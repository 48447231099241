import React from "react";

const IcdAttributesTable = ({ data }) => {
  return (
    <div className="container overflow-x-auto mx-auto bg-white border border-gray-300 rounded-lg overflow-hidden dark:border-gray-500 dark:bg-gray-900 ">
      <table className="w-full ">
        <thead>
          <tr>
            <th className="py-2 text-start px-4 bg-gray-200 border-b dark:bg-gray-800 dark:border-gray-500">
              No
            </th>
            <th className="py-2 px-4 text-start bg-gray-200  border-b dark:bg-gray-800 dark:border-gray-500">
              Type
            </th>
            {/* <th className="py-2 px-4 bg-gray-200 text-left border-b dark:bg-gray-800 dark:border-gray-500">
              Score
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left border-b dark:bg-gray-800 dark:border-gray-500">
              Relationship Score
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left border-b dark:bg-gray-800 dark:border-gray-500">
              Id
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left border-b dark:bg-gray-800 dark:border-gray-500">
              Begin Offset
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left border-b dark:bg-gray-800 dark:border-gray-500">
              End Offset
            </th> */}
            <th className="py-2 px-4 text-start bg-gray-200  border-b dark:bg-gray-800 dark:border-gray-500">
              Text
            </th>
            {/* <th className="py-2 px-4 bg-gray-200 text-left border-b">Traits</th> */}
          </tr>
        </thead>

        <tbody>
          {data.map((attribute, index) => (
            <tr key={index} className="border-b dark:border-gray-500">
              <td className="py-2 text-start px-4">{index + 1}</td>
              <td className="py-2 px-4 text-start">{attribute.Type}</td>
              {/* <td className="py-2 px-4">{attribute.Score.toFixed(6)}</td>
              <td className="py-2 px-4">
                {attribute.RelationshipScore.toFixed(6)}
              </td>
              <td className="py-2 px-4">{attribute.Id}</td>
              <td className="py-2 px-4">{attribute.BeginOffset}</td>
              <td className="py-2 px-4">{attribute.EndOffset}</td> */}
              <td className="py-2 px-4 text-start">{attribute.Text}</td>
              {/* <td className="py-2 px-4">
                {attribute.Traits.length > 0
                  ? attribute.Traits.join(", ")
                  : "None"}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      {/* {data.map(
        (attribute, index) =>
          attribute?.Traits?.length > 0 && (
            <div>
              <h3 className="text-xl mb-3 font-semibold">Traits:</h3>

              <IcdTraitsTable data={attribute?.Traits} />
            </div>
          )
      )} */}
    </div>
  );
};

export default IcdAttributesTable;
