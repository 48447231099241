import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const DynamicChatMessage = ({ content }) => {
  return (
    <div className="dynamic-chat-message">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          h1: ({ node, ...props }) => (
            <h1 className="text-2xl font-poppins font-bold mb-4" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2
              className="text-xl font-poppins font-semibold mb-3"
              {...props}
            />
          ),
          h3: ({ node, ...props }) => (
            <h3 className="text-lg  font-poppinsfont-medium mb-2" {...props} />
          ),
          p: ({ node, ...props }) => <p className="mb-4" {...props} />,
          ul: ({ node, ...props }) => (
            <ul className="list-disc  font-poppins pl-5 mb-4" {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol className="list-decimal font-poppins pl-5 mb-4" {...props} />
          ),
          li: ({ node, ...props }) => (
            <li className="mb-1 font-poppins" {...props} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default DynamicChatMessage;
