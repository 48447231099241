import React from "react";

const ICD10CMConceptsTable = ({ data }) => {
  return (
    <div className="container overflow-x-auto mx-auto bg-white dark:bg-gray-900 border dark:border-gray-500 border-gray-300 rounded-lg overflow-hidden">
      <table className="w-full">
        <thead>
          <tr>
            <th className="py-2 px-4 text-start bg-gray-200 dark:bg-gray-800  border-b dark:border-gray-500">
              No
            </th>
            <th className="py-2 px-4 text-start bg-gray-200 dark:bg-gray-800  border-b dark:border-gray-500">
              Description
            </th>
            <th className="py-2 px-4 text-start bg-gray-200 dark:bg-gray-800  border-b dark:border-gray-500">
              Code
            </th>
            {/* <th className="py-2 px-4 bg-gray-200 dark:bg-gray-800 text-left border-b dark:border-gray-500">
              Score
            </th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((concept, index) => (
            <tr key={index} className="border-b dark:border-gray-500">
              <td className="py-2 px-4 text-start">{index + 1}</td>
              <td className="py-2 px-4 text-start">{concept.Description}</td>
              <td className="py-2 px-4 text-start">{concept.Code}</td>
              {/* <td className="py-2 px-4">{concept.Score.toFixed(6)}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ICD10CMConceptsTable;
