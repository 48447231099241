import React, { useState } from "react";

const applicationLists = [
  {
    id: "APP001",
    name: "John Doe - Home Loan",
    amount: "$150,000.00",
    status: "Approved",
    date: "2024-08-01",
    details: {
      loanType: "Home Loan",
      interestRate: "3.5%",
      term: "30 years",
      applicationDate: "2024-07-15",
      approvedAmount: "$150,000.00",
      notes: "Approved with standard conditions.",
    },
  },
  {
    id: "APP002",
    name: "Jane Smith - Auto Loan",
    amount: "$25,000.00",
    status: "Pending",
    date: "2024-08-05",
    details: {
      loanType: "Auto Loan",
      interestRate: "4.2%",
      term: "5 years",
      applicationDate: "2024-07-20",
      approvedAmount: "$25,000.00",
      notes: "Pending additional documentation.",
    },
  },
  {
    id: "APP003",
    name: "Michael Johnson - Personal Loan",
    amount: "$10,000.00",
    status: "Rejected",
    date: "2024-08-10",
    details: {
      loanType: "Personal Loan",
      interestRate: "5.0%",
      term: "3 years",
      applicationDate: "2024-07-25",
      approvedAmount: "$0.00",
      notes: "Rejected due to credit score issues.",
    },
  },
  {
    id: "APP004",
    name: "Emily Davis - Business Loan",
    amount: "$500,000.00",
    status: "Approved",
    date: "2024-08-15",
    details: {
      loanType: "Business Loan",
      interestRate: "6.0%",
      term: "10 years",
      applicationDate: "2024-08-01",
      approvedAmount: "$500,000.00",
      notes: "Approved with collateral.",
    },
  },
  {
    id: "APP005",
    name: "David Wilson - Education Loan",
    amount: "$20,000.00",
    status: "Pending",
    date: "2024-08-20",
    details: {
      loanType: "Education Loan",
      interestRate: "3.8%",
      term: "5 years",
      applicationDate: "2024-08-10",
      approvedAmount: "$20,000.00",
      notes: "Pending review of academic records.",
    },
  },
];

const LendingCopilot = () => {
  const [applications, setApplications] = useState(applicationLists);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedApplication, setSelectedApplication] = useState(null);

  const handleViewDetails = (application) => {
    setSelectedApplication(application);
  };

  const handleCloseDetails = () => {
    setSelectedApplication(null);
  };

  const filteredApplications = applications.filter(
    (app) =>
      app.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      app.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col">
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Lending Copilot
          </h2>
          <p className="text-gray-600 mb-4">
            Manage and review loan applications efficiently.
          </p>

          <div className="mb-6">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search applications..."
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 bg-white shadow-md rounded-lg">
              <thead>
                <tr>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredApplications.length > 0 ? (
                  filteredApplications.map((app, index) => (
                    <tr key={index}>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {app.id}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {app.name}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {app.amount}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {app.status}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {app.date}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        <button
                          onClick={() => handleViewDetails(app)}
                          className="text-blue-500 hover:text-blue-600 font-medium mr-4"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="py-4 px-6 text-center text-gray-500"
                    >
                      No applications found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        {selectedApplication && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg mx-auto">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Application Details
              </h3>
              <p className="text-gray-700 mb-2">
                <strong>ID:</strong> {selectedApplication.id}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>Name:</strong> {selectedApplication.name}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>Amount:</strong> {selectedApplication.amount}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>Status:</strong> {selectedApplication.status}
              </p>
              <p className="text-gray-700 mb-4">
                <strong>Date:</strong> {selectedApplication.date}
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleCloseDetails}
                  className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default LendingCopilot;
