import React, { useState, useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import TimeIcon from "../assets/icons/TimeIcon.js";
import AiIconInput from "../assets/icons/AiIconInput.js";
import SendIconInput from "../assets/icons/SendIconInput.js";
import HistorySideBar from "./HistorySideBar.js";
import { Document, Page, pdfjs } from "react-pdf";
import { FaChevronDown } from "react-icons/fa";
import { motion } from "framer-motion";
import {
  Upload,
  ZoomIn,
  ZoomOut,
  ChevronLeft,
  ChevronRight,
  Maximize,
  Printer,
  MoreHorizontal,
  // Send,
  RefreshCw,
} from "react-feather";
import FileUploadModel from "./FileUploadModel.js";
import {
  BEDROCK_PROMPT_OPTONS,
  GPT_PROMPT_OPTIONS,
} from "../utils/constant.js";
import { LuPanelLeftOpen } from "react-icons/lu";
// import PdfViewerFilesSidebar from './PdfViewerFilesSidebar.js';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.min.js?v=${Date.now()}`;

const AI_INPUT_Content = [
  "Summarize this document",
  "How does Bitcoin work?",
  "What are nodes?",
  "What is a POC?",
];

const SmartSummarizerData = [
  {
    id: 1,
    icon: "🚀",
    description:
      "This Smart PDF Summarizer enhances and expedites document interaction.",
  },
  {
    id: 2,
    icon: "💡",
    description: "Upload as many PDF files as you like.",
  },
  {
    id: 3,
    icon: "ℹ️",
    description:
      "Ask a question, and receive citations that link back to the source of information in the same PDF.",
  },
];

const Header = ({
  currentPage,
  totalPages,
  scale,
  onPageChange,
  onZoomChange,
  onFullScreen,
  onPrint,
}) => (
  <div className="flex items-center border-b justify-between p-3 bg-gray-50 rounded-t-xl dark:bg-gray-800 dark:border-gray-600 text-gray-800 dark:text-white sticky top-0 z-10 shadow-sm border-gray-200">
    <div className="flex items-center space-x-2">
      <button
        onClick={() => onPageChange(-1)}
        className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
        disabled={currentPage === 1}
      >
        <ChevronLeft size={20} />
      </button>
      <div className="flex items-center bg-gray-200 dark:bg-gray-700 rounded px-2 py-1">
        <input
          type="number"
          value={currentPage}
          onChange={(e) => {
            const page = Math.max(
              1,
              Math.min(parseInt(e.target.value) || 1, totalPages)
            );
            onPageChange(page - currentPage);
          }}
          className="w-12 bg-transparent text-center focus:outline-none"
        />
        <span className="text-gray-600 dark:text-gray-400">/ {totalPages}</span>
      </div>
      <button
        onClick={() => onPageChange(1)}
        className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
        disabled={currentPage === totalPages}
      >
        <ChevronRight size={20} />
      </button>
    </div>
    <div className="flex items-center space-x-2">
      <button
        onClick={() => onZoomChange(-0.1)}
        className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
      >
        <ZoomOut size={20} />
      </button>
      <div className="bg-gray-200 dark:bg-gray-700 rounded px-2 py-1">
        <span className="text-sm">{Math.round(scale * 100)}%</span>
      </div>
      <button
        onClick={() => onZoomChange(0.1)}
        className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
      >
        <ZoomIn size={20} />
      </button>
      <button
        className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
        onClick={() => onFullScreen()}
      >
        <Maximize size={20} />
      </button>
      <button
        className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
        onClick={() => onPrint()}
      >
        <Printer size={20} />
      </button>
      <button className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors">
        <MoreHorizontal size={20} />
      </button>
    </div>
  </div>
);

const SinglePdfSummarizer = ({
  setPdfFile,
  setFiles,
  setPdfUploadData,
  pdfUploadData,
  files,
  pdfFile,
  setIsOpenFileList,
  isOpenFileList,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [scale, setScale] = useState(1.1);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const pdfContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isMessageLoading, setMessageLoading] = useState(false);
  const [isShowHistory, setShowHistory] = useState(false);
  const [isAiContent, setIsAiContent] = useState(false);
  const [fileModelOpen, setFileModelOpen] = useState(false);

  const [isFileLoading] = useState(false);
  const [selectedGpt, setSelectedGpt] = useState(GPT_PROMPT_OPTIONS[0]);
  const [selectedBedrock, setSelectedBedrock] = useState(
    BEDROCK_PROMPT_OPTONS[0]
  );

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    //set({ isUploadLoading: true, error: null });
    // const { user } = useAuthStore.getState();

    try {
      setIsLoading(true);
      const formData = new FormData();

      // Append the file first
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_API_PDF_VIEW_PATH}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setMessages([]);
        setIsLoading(false);
        setPdfFile(URL.createObjectURL(file));
        file.isUploaded = true;
        file.isLoading = false;
        file.pdf_name = response?.data?.pdf_name;
        setFiles((prevFiles) => [...prevFiles, file]);
        toast.success("PDF file uploaded successfully!");
        setPdfUploadData(response.data);
      } else {
        setIsLoading(false);
        toast.error("PDF file upload failed.");
        throw new Error("Upload failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading PDF:", error);
      //  set({ error: "Failed to upload PDF.", isUploadLoading: false });
      toast.error("Error uploading PDF file.");
    }
  };

  const handleZoom = (delta) => {
    setScale((prevScale) => Math.max(0.5, Math.min(prevScale + delta, 2)));
  };

  const handlePageChange = useCallback(
    (delta) => {
      setCurrentPage((prevPage) => {
        const newPage = Math.max(1, Math.min(prevPage + delta, numPages));
        if (pdfContainerRef.current) {
          const pageElement = pdfContainerRef.current.querySelector(
            `[data-page-number="${newPage}"]`
          );
          if (pageElement) {
            pageElement.scrollIntoView({ behavior: "smooth" });
          }
        }
        return newPage;
      });
    },
    [numPages]
  );

  const handlePageMove = useCallback(
    (pageNumber) => {
      setCurrentPage(() => {
        const newPage = Math.max(1, Math.min(pageNumber, numPages));
        if (pdfContainerRef.current) {
          const pageElement = pdfContainerRef.current.querySelector(
            `[data-page-number="${newPage}"]`
          );
          if (pageElement) {
            pageElement.scrollIntoView({ behavior: "smooth" });
          }
        }
        return newPage;
      });
    },
    [numPages]
  );

  const handleSendMessage = async () => {
    if (input.trim()) {
      setMessages((prev) => [...prev, { text: input, sender: "user" }]);
      setInput("");

      setTimeout(() => {
        const messagesList = document.querySelector("#messagesList");

        messagesList.scrollTo({
          top: messagesList.scrollHeight,
          behavior: "smooth",
        });
      }, 5000);
      try {
        // Make API call
        setMessageLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_PDF_VIEW_PATH}/get_answer`,
          {
            pdf_name: pdfUploadData?.pdf_name,
            question: input,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setMessageLoading(false);
          setMessages((prev) => [
            ...prev,
            {
              text: response?.data?.answer,
              sender: "ai",
              sources: response?.data?.sources,
            },
          ]);
        } else {
          setMessageLoading(false);
          toast.error("Message sending failed.");
          throw new Error("Message failed");
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (pdfContainerRef.current) {
        const pages =
          pdfContainerRef.current.querySelectorAll(".react-pdf__Page");
        // const containerTop = pdfContainerRef.current.scrollTop;
        const containerHeight = pdfContainerRef.current.clientHeight;
        let closestPage = 1;
        let smallestDistance = Infinity;

        pages.forEach((page) => {
          const rect = page.getBoundingClientRect();
          const distance = Math.abs(
            rect.top + rect.height / 2 - containerHeight / 2
          );
          if (distance < smallestDistance) {
            smallestDistance = distance;
            closestPage = parseInt(page.getAttribute("data-page-number"));
          }
        });

        setCurrentPage(closestPage);
      }
    };

    const container = pdfContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const iframeRef = useRef(null);
  const handlePrint = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    }
  };

  const handleFullScreen = () => {
    if (pdfContainerRef.current) {
      if (pdfContainerRef.current.requestFullscreen) {
        pdfContainerRef.current.requestFullscreen();
      } else if (pdfContainerRef.current.mozRequestFullScreen) {
        pdfContainerRef.current.mozRequestFullScreen(); // Firefox
      } else if (pdfContainerRef.current.webkitRequestFullscreen) {
        pdfContainerRef.current.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (pdfContainerRef.current.msRequestFullscreen) {
        pdfContainerRef.current.msRequestFullscreen(); // IE/Edge
      }
    }
  };

  const handleFileUploadMultiple = async (file) => {
    try {
      const formData = new FormData();

      // Append the file first
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_API_PDF_VIEW_PATH}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        //setPdfUploadData(response.data);
        return response?.data;
      } else {
        setIsLoading(false);
        toast.error("PDF file upload failed.");
        throw new Error("Upload failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading PDF:", error);
      //  set({ error: "Failed to upload PDF.", isUploadLoading: false });
      toast.error("Error uploading PDF file.");
    }
  };

  const uploadFilesMultiple = async (files) => {
    // Iterate over each file and handle its upload
    const updatedFiles = await Promise.all(
      files.map(async (file, index) => {
        if (!file.isUploaded) {
          // Set IsLoading to true when uploading starts
          // let updatedFile = { ...file, isLoading: true };

          setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            file.isUploaded = false;
            file.isLoading = true;
            updatedFiles[index] = file;
            return updatedFiles;
          });

          try {
            // Simulate file upload with a fake API request (use your real API endpoint here)
            const response = await handleFileUploadMultiple(file); // Function to handle API upload

            // Once upload is complete, set isUploaded to true
            // updatedFile = {
            //   ...updatedFile,
            //   isUploaded: true,
            //   IsLoading: false,
            // };
            file.isUploaded = true;
            file.isLoading = false;
            file.pdf_name = response.pdf_name;

            setFiles((prevFiles) => {
              const updatedFiles = [...prevFiles];

              updatedFiles[index] = file;
              return updatedFiles;
            });
          } catch (error) {
            console.error("Error uploading file:", file.name, error);
            //  updatedFile = { ...updatedFile, IsLoading: false }; // Stop loading on failure
          }

          // Return updated file with new properties
          return file;
        } else {
          // Skip the file if it's already uploaded
          return file;
        }
      })
    );

    // Return the updated list of files
    return updatedFiles;
  };

  const handleMultipleFileUpload = async () => {
    setFileModelOpen(false);
    await uploadFilesMultiple(files);
  };

  useEffect(() => {
    if (files && files.length > 1 && pdfFile) {
      setNumPages(null);
    }
  }, [pdfFile, files]);
  return (
    <div className="h-[calc(100%-47px)]">
      <div className="px-6 h-[61px] border-gray-200 dark:border-gray-600 flex items-center justify-between">
        <div>
          {!isOpenFileList && (
            <button
              className=" dark:text-gray-300"
              onClick={() => {
                setIsOpenFileList(!isOpenFileList);
              }}
              disabled={files.length < 1}
            >
              <LuPanelLeftOpen size={20} />
            </button>
          )}
        </div>
        <div className="flex py-2.5 items-center gap-4">
          <button
            onClick={() => setShowHistory(!isShowHistory)}
            className="text-[#D8E6FF] dark:text-gray-300"
          >
            <TimeIcon />
          </button>
          {pdfFile && (
            <div className="flex items-center justify-center h-full">
              <button
                className="cursor-pointer flex items-center font-medium space-x-2 bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors"
                onClick={() => setFileModelOpen(true)}
              >
                {isLoading ? (
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{
                      duration: 1,
                      repeat: Infinity,
                      ease: "linear",
                    }}
                  >
                    <RefreshCw size={20} className="mr-2" />
                  </motion.div>
                ) : (
                  <Upload size={20} className="mr-2" />
                )}

                <span> {isLoading ? "Uploading..." : "Upload file"}</span>
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="grid p-6 lg:grid-cols-2 gap-6 lg:flex-row lg:h-[calc(100%-61px)] h-auto text-gray-800 dark:text-white">
        <div className="lg:w-full border rounded-xl border-gray-200 overflow-y-auto dark:border-gray-600  max-h-[calc(100vh-61px)] h-full flex flex-col">
          <Header
            currentPage={currentPage}
            totalPages={numPages}
            scale={scale}
            onPageChange={handlePageChange}
            onZoomChange={handleZoom}
            cla
            onFullScreen={handleFullScreen}
            onPrint={handlePrint}
          />
          <iframe
            ref={iframeRef}
            src={pdfFile}
            style={{ display: "none" }}
            title="pdf"
          />
          <div
            ref={pdfContainerRef}
            className="flex-grow overflow-auto rounded-b-xl bg-gray-50 dark:bg-gray-800"
          >
            {pdfFile ? (
              <Document
                file={pdfFile}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={scale}
                    className="mb-4"
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                ))}
              </Document>
            ) : (
              <div className="flex items-center justify-center h-full min-h-[300px]">
                <label
                  htmlFor="pdf-upload"
                  className="cursor-pointer flex items-center font-medium space-x-2 bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  {isLoading ? (
                    <motion.div
                      animate={{ rotate: 360 }}
                      transition={{
                        duration: 1,
                        repeat: Infinity,
                        ease: "linear",
                      }}
                    >
                      <RefreshCw size={20} className="mr-2" />
                    </motion.div>
                  ) : (
                    <Upload size={20} className="mr-2" />
                  )}

                  <span> {isLoading ? "Uploading..." : "Upload file"}</span>
                </label>
                <input
                  id="pdf-upload"
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileUpload}
                  className="hidden"
                />
              </div>
            )}
          </div>
        </div>

        <div className="lg:w-full max-h-[calc(100vh-61px)] overflow-auto h-full lg:h-full gap-4 flex flex-col">
          <div className="flex-grow overflow-auto p-4" id="messagesList">
            {messages.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className={`mb-4 ${
                  message.sender === "user" ? "text-right" : "text-left"
                }`}
              >
                <div
                  className={`inline-block p-3 rounded-lg ${
                    message.sender === "user"
                      ? "bg-blue-500 dark:bg-blue-600 text-white"
                      : "bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white"
                  }`}
                >
                  <p className="text-sm font-semibold mb-1">
                    {message.sender === "user" ? "You" : "AI Assistant"}
                  </p>
                  <p>{message.text}</p>
                </div>
                <div className="flex items-center gap-2">
                  {message?.sender &&
                    message.sender === "ai" &&
                    message?.sources?.map((item, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageMove(item + 1)}
                        className="mt-3 bg-gray-50 rounded-md border w-fit px-2 py-0.5 border-gray-300"
                      >
                        <span>p. {item + 1}</span>
                      </button>
                    ))}
                </div>
              </motion.div>
            ))}

            {messages.length === 0 ? (
              <div className="h-full w-full flex flex-col items-center justify-center p-5">
                <div className="flex flex-col gap-4 items-center justify-center">
                  <img
                    src="/images/SmartSummarizerLogo.png"
                    className="w-10 h-10"
                    alt=""
                  />
                  <h4 className="font-semibold text-xl leading-7 text-center">
                    Smart Summarizer
                  </h4>
                </div>
                <div className="flex flex-col gap-4 my-9 max-w-[340px] w-full">
                  {SmartSummarizerData.map((item, index) => (
                    <div key={index} className="flex gap-5">
                      <div>{item.icon}</div>
                      <p className="font-normal text-sm leading-5 -tracking-[2%] text-[#252525] dark:text-white">
                        {item.description}
                      </p>
                    </div>
                  ))}
                </div>
                <button className="flex gap-2.5 items-center border border-[#D8E6FF] rounded-md px-4 py-2">
                  <svg
                    width="13"
                    height="15"
                    viewBox="0 0 13 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.772 5.73424C12.0922 5.90451 12.36 6.1587 12.5468 6.46956C12.7336 6.78042 12.8323 7.13625 12.8323 7.4989C12.8323 7.86156 12.7336 8.21738 12.5468 8.52824C12.36 8.83911 12.0922 9.09329 11.772 9.26357L3.23068 13.9082C1.85535 14.6569 0.166016 13.6836 0.166016 12.1442V2.85424C0.166016 1.31424 1.85535 0.34157 3.23068 1.0889L11.772 5.73424Z"
                      fill="currentColor"
                    />
                  </svg>

                  <span className="text-black dark:text-white font-semibold leading-6">
                    Take the tour
                  </span>
                </button>
              </div>
            ) : null}

            {isMessageLoading && (
              <div className="flex flex-col items-start gap-2.5">
                <div className="dotPulse text-[#737373] dark:text-white text-sm font-poppins leading-5 font-normal">
                  <span className="pr-0.5">Searching for relevant sources</span>
                  <span className="dot dark:darkDot" />
                  <span className="dot dark:darkDot" />
                  <span className="dot dark:darkDot" />
                </div>
              </div>
            )}
          </div>

          <div className="p-4 border flex flex-col gap-2 border-gray-200 dark:border-gray-600 bg-white rounded-xl dark:bg-gray-800">
            <div className="relative">
              {/* <button type="button" onClick={() => setIsAiContent(true)}>
                <AiIconInput />
              </button> */}

              {isAiContent && (
                <div className="p-4 border border-gray-200 rounded-md absolute bottom-8 bg-white">
                  <div>
                    <button
                      type="button"
                      onClick={() => setIsAiContent(false)}
                      className="flex items-center outline-none gap-2 py-[3px] text-black text-xs font-semibold"
                    >
                      Don’t know what to ask? Try these <FaChevronDown />
                    </button>

                    <div className="flex flex-col gap-2 w-full mt-2">
                      {AI_INPUT_Content?.map((text, index) => (
                        <button
                          key={index}
                          type="button"
                          className="flex py-1 px-3 rounded-md w-full hover:bg-gray-100 px items-center outline-none gap-2 text-gray-500 text-xs font-semibold"
                        >
                          {text}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center rounded-lg overflow-hidden">
              <input
                type="text"
                value={input}
                disabled={!pdfUploadData}
                onChange={(e) => setInput(e.target.value)}
                className="flex-grow pr-3 bg-transparent focus:outline-none text-sm dark:text-white dark:placeholder:text-white placeholder:text-gray-500 text-black"
                placeholder="Ask a question..."
                onKeyDown={handleKeyDown}
              />

              <button onClick={handleSendMessage} disabled={!pdfUploadData}>
                <SendIconInput />
              </button>
            </div>
          </div>
        </div>
      </div>

      <FileUploadModel
        handleMultipleFileUpload={handleMultipleFileUpload}
        isOpen={fileModelOpen}
        files={files}
        setFiles={setFiles}
        setIsOpen={setFileModelOpen}
        isLoading={isFileLoading}
        setSelectedGpt={setSelectedGpt}
        selectedGpt={selectedGpt}
        selectedBedrock={selectedBedrock}
        setSelectedBedrock={setSelectedBedrock}
      />

      {isShowHistory && <HistorySideBar />}
    </div>
  );
};

export default SinglePdfSummarizer;
