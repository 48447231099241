import React, { useState, useRef } from "react";
import { Paperclip, Send } from "react-feather";

const ChatInput = ({ onSend, onFileUpload }) => {
  const [input, setInput] = useState("");
  const fileInputRef = useRef(null);

  const handleSend = () => {
    if (input.trim()) {
      onSend(input);
      setInput("");
    }
  };

  /*const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      onFileUpload(files);
      fileInputRef.current.value = "";
    }
  };*/

  return (
    <div className="p-4 bg-gray-100 dark:bg-gray-900">
      <div className="flex items-center max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-md">
        <input
          type="text"
          className="flex-1 p-3 font-poppins bg-transparent text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
          placeholder="Type a message..."
        />
        <input
          type="file"
          multiple
          //onChange={handleFileChange}
          className="hidden"
          id="file-input"
          disabled
          ref={fileInputRef}
        />
        <label
          htmlFor="file-input"
          className="p-3 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white transition-colors duration-300 cursor-pointer"
        >
          <Paperclip className="h-6 w-6" />
        </label>
        <button
          className="p-3 text-blue-500 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300 focus:outline-none disabled:opacity-50"
          onClick={handleSend}
          disabled={!input.trim()}
        >
          <Send className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
