// "type": "entities"
import React from 'react';
import { motion } from 'framer-motion';

const TranscriptTab = ({ TranscriptData }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      className={`p-4 rounded-lg min-h-[200px] max-h-[500px] overflow-y-auto helpdesk-custom-scrollbar dark:bg-gray-700 bg-white`}
    >
      {/* {TranscriptData?.map((item, index) => (
        <Accordion
          key={index}
          title={item?.SectionName}
          content={
            <div className="mt-1">
              {item?.Summary?.map((data, index) => (
                <p key={index}>{data?.SummarizedSegment}</p>
              ))}
            </div>
          }
        />
      ))} */}
      <div className="p-6 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md w-full mx-auto my-8">
        {TranscriptData.map((item, index) => {
          const [speaker, dialogue] = item.split(':');
          const isSpeaker1 = speaker.trim() === 'Speaker 1';

          return (
            <div
              key={index}
              className={`flex items-start my-4 ${
                isSpeaker1 ? 'justify-start' : 'justify-end'
              }`}
            >
              <div
                className={`max-w-3xl p-4 rounded-lg shadow-sm text-white ${
                  isSpeaker1
                    ? 'bg-gradient-to-r from-blue-500 to-purple-600'
                    : 'bg-gradient-to-r from-teal-500 to-fuchsia-600'
                }`}
              >
                <p className="font-bold">{speaker.trim()}</p>
                <p className="mt-2">{dialogue.trim()}</p>
              </div>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default TranscriptTab;
