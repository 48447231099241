import { NavigateFunction } from 'react-router-dom';

export const redirectBasedOnRole = (role, navigate) => {
  switch (role) {
    case 'Customer':
    case 'HR Employee':
    case 'IT Employee':
    case 'Sales Employee':
      navigate('/chat');
      break;
    case 'HR Manager':
    case 'IT Manager':
    case 'Sales Manager':
    case 'Super Admin':
      navigate('/dashboard');
      break;
    default:
      navigate('/login');
  }
};