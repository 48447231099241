/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

import { Chart as Legend } from 'chart.js';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { CrossArrowIcon } from '../../assets/icons/crossArrowIcon';
import { UpArrow } from '../../assets/icons/UpArrow';
import { DownArrow } from '../../assets/icons/DownArrow';
import { IIcon } from '../../assets/icons/IIcon';
import { StarIcon } from '../../assets/icons/StarIcon';

const MapSection = () => {
  const cardData = [
    {
      title: 'Total income',
      value: '$26,678.67',
      percentage: 6.5,
      up: true,
      description: 'since last month',
    },
    {
      title: 'Total debt',
      value: '$6,792',
      percentage: 6.2,
      up: false,
      description: 'since last month',
    },
    {
      title: 'Total cases resolved',
      value: '4k',
      percentage: 6.5,
      up: true,
      description: 'since last month',
    },
  ];

  const colors = ['#EBF2FF', '#D8E6FF', '#377FFF', '#E2E0E5'];

  const data = [
    { data: { value1: 2000, value2: 4000, value3: 6000 } },
    { data: { value1: 2000, value2: 6000, value3: 4000 } },
    { data: { value1: 4000, value2: 2000, value3: 6000 } },
    { data: { value1: 2000, value2: 4000, value3: 6000 } },
    { data: { value1: 8000, value2: 2000, value3: 2000 } },
    { data: { value1: 4000, value2: 2000, value3: 6000 } },
  ];

  const flattenedData = data.map((item) => ({
    name: item.name,
    value1: item.data.value1,
    value2: item.data.value2,
    value3: item.data.value3,
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-2 border">
          <p className="label">{`${label}`}</p>
          <p className="text-xs">{`Human Support: ${payload[0].value}`}</p>
          <p className="text-xs">{`Avahi AI: ${payload[1].value}`}</p>
          <p className="text-xs">{`Another Category: ${payload[2].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 mt-6">
      {cardData.map((card, index) => (
        <div
          key={index}
          className="bg-transparent dark:border-gray-600 px-9 py-6 rounded-[15px] border-[#E2E0E5] border-[1px] flex flex-col gap-5"
        >
          <div className="flex items-center justify-between">
            <p className="text-base font-medium leading-6">{card.title}</p>
            <CrossArrowIcon />
          </div>
          <div>
            <div className="flex gap-4 items-center">
              <p className="text-2xl font-medium leading-9">{card.value}</p>
              <div
                className={`flex items-center rounded-full py-1.5 px-3 w-fit ${
                  card.up ? 'bg-[#238B5B26]' : 'bg-[#FFD7D7]'
                }`}
              >
                <p
                  className={`text-xs ${
                    card.up ? 'text-[#238B5B]' : 'text-[#F5095C]'
                  }`}
                >
                  {card.percentage}%
                </p>
                {card.up ? <UpArrow /> : <DownArrow />}
              </div>
            </div>
            <p className="text-sm font-normal text-[#787878]">
              {card.description}
            </p>
          </div>
        </div>
      ))}
      <div className="bg-transparent px-9 py-6 rounded-[15px] dark:border-gray-600 border-[#E2E0E5] border-[1px] flex flex-col gap-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 text-base font-medium leading-6">
            Total tickets <IIcon />
          </div>
        </div>
        <div>
          <div className="flex gap-4 items-center">
            <p className="text-2xl font-medium leading-9">9k</p>
            <div
              className={`flex items-center rounded-full py-1.5 px-3 w-fit ${
                'Up' ? 'bg-[#238B5B26]' : 'bg-[#FFD7D7]'
              }`}
            >
              <p
                className={`text-xs ${
                  'Up' ? 'text-[#238B5B]' : 'text-[#F5095C]'
                }`}
              >
                6.5%
              </p>
              {'Up' ? <UpArrow /> : <DownArrow />}
            </div>
          </div>
        </div>

        <ResponsiveContainer width="100%" height={230}>
          <BarChart
            width={500}
            height={180}
            data={flattenedData}
            margin={{ top: 20 }}
          >
            <CartesianGrid vertical={false} stroke="#ccc" />
            <XAxis dataKey="name" tick={{ fill: '#ccc' }} axisLine={false} />
            <YAxis
              tick={{ fill: '#ccc' }}
              tickFormatter={(value) => `${value / 1000}k`}
              axisLine={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />

            <Bar
              dataKey="value1"
              name="Human Support"
              fill="#EBF2FF"
              radius={[3, 3, 0, 0]}
              barSize={8}
            />
            <Bar
              dataKey="value2"
              name="Avahi AI"
              fill="#D8E6FF"
              radius={[3, 3, 0, 0]}
              barSize={8}
            />
            <Bar
              dataKey="value3"
              name="Another Category"
              fill="#377FFF"
              radius={[3, 3, 0, 0]}
              barSize={8}
            />
          </BarChart>
        </ResponsiveContainer>
        <div className="flex gap-2">
          {['Human support', 'Avahi AI', 'Avahi AI'].map((segment, index) => (
            <div
              key={index}
              className="flex items-center gap-0.5 text-[10px] font-normal"
            >
              <div
                className="w-1.5 h-1.5 rounded-full border-black border-[0.2px]"
                style={{
                  backgroundColor: colors[index % colors.length],
                }}
              />
              <span>{segment}</span>
            </div>
          ))}
        </div>

        <div className="flex gap-2 text-blue-20">
          <StarIcon />
          <p className="text-sm font-light text-[#2D2D2D] dark:text-white">
            Avahi AI has had more tickets garnered than any other from of
            support
          </p>
        </div>
      </div>
      <div className="bg-transparent rounded-[15px] dark:border-gray-600 border-[#E2E0E5] border-[1px] flex flex-col gap-5 overflow-hidden relative">
        {/* <img src="/images/map.png" className="h-full" alt="" />
      <button class="absolute top-2.5 left-2.5 flex gap-1.5 items-center px-3 py-2 bg-white text-black font-medium rounded-lg hover:bg-white">
        <span>ROI by branch</span>
        <IIcon />
      </button> */}
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              src="https://maps.google.com/maps?q=1390%20Market%20Street,%20Suite%20200%20San%20Francisco,%20CA%2094102%20United%20States&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
              frameBorder="0"
              scrolling="no"
              style={{ width: '590px', height: '475px' }}
            ></iframe>
            <style jsx>{`
              .mapouter {
                display: table;
              }
              .gmap_canvas {
                overflow: hidden;
                position: relative;
                height: 100%;
                width: 590px;
                background: #fff;
              }
              .gmap_canvas iframe {
                position: relative !important;
                z-index: 2 !important;
              }
              .gmap_canvas a {
                color: #fff !important;
                position: absolute !important;
                top: 0 !important;
                left: 0 !important;
                z-index: 0 !important;
              }
            `}</style>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="bg-transparent px-9 py-6 rounded-[15px] dark:border-gray-600 border-[#E2E0E5] border-[1px] flex flex-col gap-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-base font-medium leading-6">
              Debt tracking <IIcon />
            </div>
          </div>
          <div>
            <div className="flex items-center gap-4">
              <p className="text-2xl font-medium leading-9">74.4%</p>
              <p className="text-[#252525] dark:text-white font-medium text-base">
                <span className="text-[#F5095C] font-semibold">+10% </span>
                since last month
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex justify-between text-black dark:text-white font-medium text-sm">
              <p>Progress</p>
              <p>25.6% to get 100%</p>
            </div>

            <div className="flex rounded overflow-hidden w-full">
              {['10%', '35%', '25%', '30%'].map((percentage, index) => (
                <div
                  key={index}
                  className="h-5"
                  style={{
                    width: percentage,
                    backgroundColor: colors[index % colors.length],
                  }}
                />
              ))}
            </div>

            <div className="flex gap-3">
              {['Fremont Bank', 'JPM', 'Wells Fargo'].map((segment, index) => (
                <div
                  key={index}
                  className="flex items-center gap-0.5 text-[10px] font-normal"
                >
                  <div
                    className="w-1.5 h-1.5 rounded-full border-black border-[0.2px]"
                    style={{
                      backgroundColor: colors[index % colors.length],
                    }}
                  />
                  <span>{segment}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-transparent dark:border-gray-600 px-9 py-6 rounded-[15px] border-[#E2E0E5] border-[1px] flex flex-col gap-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-base font-medium leading-6">
              Quarter 2 tracking <IIcon />
            </div>
          </div>
          <div>
            <div className="flex items-center gap-4">
              <p className="text-2xl font-medium leading-9">34.4%</p>
              <p className="text-[#252525] dark:text-white font-medium text-base">
                <span className="text-[#F5095C] font-semibold">+10% </span>
                since last month
              </p>
            </div>
          </div>

          <div className="flex justify-between text-black dark:text-white font-medium text-sm">
            <p>Progress</p>
            <p>65.6% to get 100%</p>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex rounded overflow-hidden w-full">
              {['30%', '10%', '40%', '20%'].map((percentage, index) => (
                <div
                  key={index}
                  className="h-5"
                  style={{
                    width: percentage,
                    backgroundColor: colors[index % colors.length],
                  }}
                />
              ))}
            </div>
            <div className="flex gap-3">
              {['Fremont Bank', 'JPM', 'Wells Fargo'].map((segment, index) => (
                <div
                  key={index}
                  className="flex items-center gap-0.5 text-[10px] font-normal"
                >
                  <div
                    className="w-1.5 h-1.5 rounded-full border-black border-[0.2px]"
                    style={{
                      backgroundColor: colors[index % colors.length],
                    }}
                  />
                  <span>{segment}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapSection;
