/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CloseIcon from '../../../assets/icons/CloseIcon';
import { LuUpload } from 'react-icons/lu';
import { StarIcon } from '../../../assets/icons/StarIcon';
import DataExtractionAccordian from '../../DataExtractionAccordian';
import { RiFileCopy2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Highlighter from 'react-highlight-words';

const DataExtractionText = ({
  isAline,
  uploadFileText,
  setUploadFileText,
  setFileModelOpen,
  handleStructureExtraction,
  isLoading,
  extractionData,
  isExtractionDisable,
  setIsExtractionDisable,
  setIsAline,
}) => {
  const [higligthedArray, setHigligthedArray] = useState([]);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Coppied');
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
      });
  };

  useEffect(() => {
    if (!extractionData) {
      setIsAline('vertical');
    }

    if (extractionData) {
      const data = [];

      Object.keys(extractionData)?.forEach((key) => {
        data?.push(extractionData[key]);
      });

      setHigligthedArray(data?.flat(1));
    }
  }, [extractionData]);

  return (
    <div
      className={`h-[calc(100%-60px)] overflow-y-auto  flex p-6 lg:p-9 gap-9 ${
        isAline === 'vertical' ? 'flex-col' : 'flex-row'
      }`}
    >
      <div
        className={`w-full  flex flex-col gap-5 h-full mx-auto min-h-[600px] lg:min-h-[400px] ${
          isAline === 'vertical'
            ? 'h-full order-1 max-w-[932px]'
            : `order-2  ${!extractionData ? ' max-w-[932px]' : 'max-w-full'} `
        }`}
      >
        <h3 className="text-xl font-medium">Title</h3>

        <div className="w-full relative h-[calc(100%-110px)]">
          {/* <textarea
            type="text"
            value={uploadFileText || ''}
            onChange={(e) => {
              setUploadFileText(e.target.value);
              setIsExtractionDisable(false);
            }}
            placeholder="Your transcript will be generated here."
            className={`resize-none ${
              uploadFileText ? 'pr-12' : ''
            } w-full h-full helpdesk-custom-scrollbar text-base dark:border-gray-700 border-lightGray-10 placeholder:text-[#787878] px-6 pt-6 pb-4 rounded-2xl bg-lightGray-100 dark:bg-gray-900 border-2  text-black dark:text-white outline-none`}
          /> */}

          <div
            className={`resize-none ${
              uploadFileText ? 'pr-12' : ''
            } w-full h-full helpdesk-custom-scrollbar overflow-y-auto text-base dark:border-gray-700 border-lightGray-10 placeholder:text-[#787878] px-6 pt-6 pb-4 rounded-2xl bg-lightGray-100 dark:bg-gray-900 border-2  text-black dark:text-white outline-none`}
          >
            <Highlighter
              highlightClassName="YourHighlightClass"
              searchWords={higligthedArray}
              autoEscape={true}
              textToHighlight={uploadFileText}
              className="text-wrap whitespace-pre-line"
            />
          </div>

          {uploadFileText && (
            <button
              className="absolute right-6 top-6"
              onClick={() => setUploadFileText('')}
            >
              <CloseIcon />
            </button>
          )}

          {uploadFileText && (
            <button
              className="absolute right-6 bottom-6 text-gray-700 dark:text-white"
              onClick={() => handleCopy(uploadFileText)}
            >
              <RiFileCopy2Line size={20} />
            </button>
          )}
        </div>

        <div className="flex items-center justify-between flex-wrap">
          <button
            type="button"
            onClick={() => setFileModelOpen(true)}
            className="py-2 rounded-lg px-3 hover:bg-blue-45 duration-300 font-poppins flex items-center gap-2 text-white text-base font-medium bg-blue-20"
          >
            <LuUpload size={20} />
            Upload file
          </button>

          <div className="flex items-center gap-3">
            {/* <button
              type="button"
              className="flex items-center hover:bg-blue-10 py-2 px-3 rounded-lg text-blackDark-10 text-base gap-2.5"
            >
              <MdOutlineSaveAlt size={24} />
            </button> */}

            <button
              type="button"
              className="py-2 rounded-lg px-3 disabled:cursor-not-allowed hover:bg-blue-45 duration-300 disabled:border-lightGray-10 border border-transparent disabled:bg-[#F2F2F2] disabled:text-[#B1B1B1] font-poppins flex items-center gap-2 text-white text-base font-medium bg-blue-20"
              onClick={() => handleStructureExtraction()}
              disabled={isLoading || !uploadFileText || isExtractionDisable}
            >
              <StarIcon />
              Extract data
            </button>
          </div>
        </div>
      </div>

      {extractionData && (
        <div
          className={`flex ${
            isAline === 'vertical'
              ? 'order-2 max-w-[932px] mx-auto'
              : 'order-1 xl:max-w-[444px] max-w-[350px]'
          } flex-col border  border-gray-10 dark:border-gray-700 rounded-2xl w-full`}
        >
          <h2 className="text-blackDark-10 text-xl font-medium p-4 bg-[#F2F2F2] dark:bg-gray-800 rounded-t-2xl dark:text-white border-b border-gray-10 dark:border-gray-700">
            Extracted Data
          </h2>

          <div
            className={`p-4 ${
              isAline === 'vertical' ? 'max-h-[300px]' : ''
            }   overflow-y-auto`}
          >
            {Object.keys(extractionData).map((key) => (
              <DataExtractionAccordian
                key={key}
                title={key}
                content={
                  <div className="mt-5 flex overflow-y-auto flex-wrap gap-6 gap-y-3">
                    {extractionData[key]?.length > 0 ? (
                      <>
                        {extractionData[key]?.map((item, index) => (
                          <p
                            className={
                              'text-blackDark-10 dark:text-white dark:bg-gray-600 bg-[#F2F2F2] text-base font-normal px-4 py-2 rounded-full'
                            }
                            key={index}
                          >
                            {item}
                          </p>
                        ))}
                      </>
                    ) : (
                      <p
                        className={
                          'text-blackDark-10 dark:text-white dark:bg-gray-600 bg-[#F2F2F2] text-base font-normal px-4 py-2 rounded-full'
                        }
                      >
                        No data found
                      </p>
                    )}
                  </div>
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataExtractionText;
