export const GPT_PROMPT_OPTIONS = [
  { id: 1, value: 'gpt-3.5', label: 'Gpt 3.5' },
  { id: 2, value: 'gpt-4', label: 'Gpt 4' },
  // { id: 3, value: 'gpt-4o', label: 'Gpt 4o' },
  { id: 3, value: 'gpt-4-turbo', label: 'Gpt 4 Turbo' },
];

export const BEDROCK_PROMPT_OPTONS = [
  {
    id: 1,
    label: 'Haiku 3.0',
    value: 'haiku-3.0',
  },
  {
    id: 2,
    label: 'Sonnet 3.5',
    value: 'sonnet-3.5',
  },
  {
    id: 3,
    label: 'Opus 3.0',
    value: 'opus-3.0',
  },
];

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1.5px solid #377FFF',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#377FFF',
    },
  }),
  IndicatorSeparator: () => null,
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? '#F4F4F4' : isFocused ? '#F4F4F4' : undefined,
    color: isSelected ? '#1084FF' : '#888888',
    '&:active': {
      backgroundColor: '#F4F4F4',
      color: '#1084FF',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: 'gray',
  }),
};
