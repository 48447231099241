import React, { useState, useCallback } from 'react';
import {
  Clipboard,
  Mic,
  RefreshCw,
  Activity,
  Music,
  ArrowDownCircle,
} from 'react-feather';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { ThemeContext } from '../components/ThemeProvider';
import { toast } from 'react-toastify';
import axios from 'axios';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import TranscriptTab from './MedicalScribingTabs/TranscriptTab';
import IcdTab from './MedicalScribingTabs/IcdTab';
import EhrSummaryTab from './MedicalScribingTabs/EhrSummaryTab';
import PhiTab from './MedicalScribingTabs/PhiTab';
import { useTranslation } from 'react-i18next';

const TAB_Data = [
  { id: 1, name: 'medicalScribing.tabs.transcript', value: 'Transcript' },
  { id: 2, name: 'medicalScribing.tabs.ehrSummary', value: 'EHR Summary' },
  { id: 4, name: 'medicalScribing.tabs.icdCode', value: 'ICD Code Breakdown' },
  // { id: 3, name: 'medicalScribing.tabs.maskData', value: 'Masked Data' },

  // { id: 5, name: "Rx_norm" },
  // { id: 6, name: "PHI" },
];

const MedicalScribing = () => {
  const [selectedTab, setSelectedTab] = useState('Transcript');

  const [file, setFile] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isSummaryBtn, setIsSummaryBtn] = useState(false);
  const [isSummarySection, setIsSummarySection] = useState(false);
  // const [transcript, setTranscript] = useState("");
  // const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  // const [uploadFileResponse, setUploadFileResponse] = useState({
  //   JobName:
  //     "avahi-d4c190f1-6144-4366-8905-1f131358a0ce_Doctor-Patient_Cost_of_Care_Conversation_234_Mitul",
  //   SummaryFileUri:
  //     "https://s3.us-east-1.amazonaws.com/sanitas-healthscribe-recorded-audio-output-bucket/avahi-d4c190f1-6144-4366-8905-1f131358a0ce_Doctor-Patient_Cost_of_Care_Conversation_234_Mitul/summary.json",
  //   TranscriptionFileUri:
  //     "https://s3.us-east-1.amazonaws.com/sanitas-healthscribe-recorded-audio-output-bucket/avahi-d4c190f1-6144-4366-8905-1f131358a0ce_Doctor-Patient_Cost_of_Care_Conversation_234_Mitul/transcript.json",
  //   TransformedFilename:
  //     "d4c190f1-6144-4366-8905-1f131358a0ce_Doctor-Patient_Cost_of_Care_Conversation_234_Mitul.wav",
  // });
  const [uploadFileResponse, setUploadFileResponse] = useState(null);
  const [transcriptResponse, setTranscriptResponse] = useState(null);
  const [reportResponse, setReportResponse] = useState(null);
  const [icd10Response, setIcd10Response] = useState(null);

  const { isDarkMode } = useContext(ThemeContext);

  const recorderControls = useAudioRecorder();
  const { t } = useTranslation();

  const fetchHealthscribeOutputsForReport = async () => {
    try {
      setIsLoading(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_HEALTHCARE}/fetch-healthscribe-outputs-for-recorded-audio/fetch-healthscribe-outputs-for-recorded-audio`,
        {
          queryStringParameters: {
            job_name: uploadFileResponse?.JobName,
            type: 'report',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'Tq6isgVNGT1v2yYpayLwA7Nuw2dmUmjXaR2UQ7jn',
          },
        }
      );

      setIsLoading(false);
      if (response?.data?.statusCode === 200) {
        setReportResponse(JSON.parse(response?.data?.body));
      } else {
        setIsLoading(false);
        toast.error('Fetch data failed.');
        throw new Error('Fetch data failed');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error Fetch data:', error);
      //  set({ error: "Failed to upload PDF.", isUploadLoading: false });
      toast.error('Error Fetch data.');
    } finally {
      setIsLoading(false);
      fetchHealthscribeOutputsForIcd10();
    }
  };

  const fetchHealthscribeOutputsForIcd10 = async () => {
    try {
      setIsLoading(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_HEALTHCARE}/fetch-healthscribe-outputs-for-recorded-audio/fetch-healthscribe-outputs-for-recorded-audio`,
        {
          queryStringParameters: {
            job_name: uploadFileResponse?.JobName,
            type: 'icd10',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'Tq6isgVNGT1v2yYpayLwA7Nuw2dmUmjXaR2UQ7jn',
          },
        }
      );

      if (response?.data?.statusCode === 200) {
        setIcd10Response(JSON.parse(response?.data?.body));
      } else {
        toast.error('Fetch data failed.');
        throw new Error('Fetch data failed');
      }
    } catch (error) {
      console.error('Error Fetch data:', error);
      //  set({ error: "Failed to upload PDF.", isUploadLoading: false });
      toast.error('Error Fetch data.');
    } finally {
      //fetchHealthscribeOutputsForIcd10();
    }
  };

  const fetchHealthscribeOutputsForTranscription = async () => {
    try {
      setIsFileLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_HEALTHCARE}/fetch-healthscribe-outputs-for-recorded-audio/fetch-healthscribe-outputs-for-recorded-audio`,
        {
          queryStringParameters: {
            job_name: uploadFileResponse?.JobName,
            type: 'transcript',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'Tq6isgVNGT1v2yYpayLwA7Nuw2dmUmjXaR2UQ7jn',
          },
        }
      );

      setIsFileLoading(false);
      if (response?.data?.statusCode === 200) {
        setTranscriptResponse(JSON.parse(response?.data?.body));
      } else {
        toast.error('Fetch data failed.');
        throw new Error('Fetch data failed');
      }
    } catch (error) {
      setIsFileLoading(false);
      console.error('Error Fetch data:', error);
      //  set({ error: "Failed to upload PDF.", isUploadLoading: false });
      toast.error('Error Fetch data.');
    } finally {
      setIsFileLoading(false);
      fetchHealthscribeOutputsForReport();
    }
  };

  const generateSummary = async () => {
    //     setIsLoading(true);
    //     setError("");
    //     try {
    //       await new Promise((resolve) => setTimeout(resolve, 2000));
    //       const dummySummary = `
    // Patient Complaint: Severe headache for 3 days
    // Symptoms:
    // - Light and sound sensitivity
    // - No fever or nausea
    // Medical History: Migraines
    // Current Medications: None
    // Recommendations:
    // 1. MRI scan
    // 2. Prescribed sumatriptan for acute relief
    // Follow-up: Schedule in 2 weeks to review MRI results and medication efficacy.
    //       `;
    //       setSummary(dummySummary.trim());
    //       setIsSummarySection(true);
    //     } catch (err) {
    //       setError(
    //         "An error occurred while generating the summary. Please try again."
    //       );
    //     } finally {
    //       setIsLoading(false);
    //     }
    setIsSummarySection(true);
    fetchHealthscribeOutputsForTranscription();
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const onDropRejected = useCallback((fileRejections) => {
    const { errors } = fileRejections[0];

    const fileTypeError = errors.find(
      (error) => error.code === 'file-invalid-type'
    );
    if (fileTypeError) {
      toast.error('Invalid file type. Only .mp3 files are allowed.');
    } else {
      toast.error(errors[0].message);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'audio/mpeg': ['.mp3', '.wav', '.webm'],
    },
    multiple: false,
  });

  const addAudioElement = (blob) => {
    const audioFile = new File([blob], 'recording.wav', {
      type: 'audio/wav',
    });

    setFile(audioFile);
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        setIsFileLoading(true);
        const formData = new FormData();

        // Append the file first
        formData.append('file', file);

        const response = await axios.post(
          `${process.env.REACT_APP_API_HEALTHCARE_UPLOAD_FILE}/upload_and_transcribe`,
          formData,
          {
            headers: {
              //"Content-Type": "multipart/form-data",
            },
            maxBodyLength: Infinity,
          }
        );

        if (response.status === 200) {
          setIsFileLoading(false);
          setIsSummaryBtn(true);
          setUploadFileResponse(response?.data);
          toast.success('Audio file uploaded successfully!');
        } else {
          setIsFileLoading(false);
          toast.error('Audio file upload failed.');
          throw new Error('Upload failed');
        }
      } catch (error) {
        setIsFileLoading(false);
        console.error('Error uploading Audio:', error);
        //  set({ error: "Failed to upload PDF.", isUploadLoading: false });
        toast.error('Error uploading Audio file.');
      }
    }
  };

  return (
    <>
      <div className={`mx-auto px-4 py-8 space-y-8 font-poppins min-h-screen`}>
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl font-bold mb-8 text-gray-800 dark:text-white flex items-center"
        >
          <Clipboard className="mr-4 text-blue-500" size={36} />
          {t('medicalScribing.title')}
        </motion.h1>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`rounded-xl shadow-lg overflow-hidden border transition-all duration-300 ${
              isDarkMode
                ? 'bg-gray-800 border-gray-700 hover:shadow-blue-500/20'
                : 'bg-white border-gray-200 hover:shadow-purple-500/20'
            }`}
          >
            <div className="p-6 space-y-6 ">
              <div>
                <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4 flex items-center">
                  <Mic size={24} className="mr-2 text-red-500" />
                  {t('medicalScribing.voiceRecording')}
                </h2>
                <div className="flex items-center justify-between">
                  <AudioRecorder
                    onRecordingComplete={(blob) => addAudioElement(blob)}
                    recorderControls={recorderControls}
                    className="w-full h-48 border-2 border-gray-300 rounded-lg overflow-hidden"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div
                  {...getRootProps()}
                  className="w-fit bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50 cursor-pointer"
                >
                  <input {...getInputProps()} />
                  <div className="flex gap-2 items-center">
                    <Music size={20} />
                    <p>{t('medicalScribing.uploadAudio')}</p>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  {file && (
                    <sapn
                      className={
                        'border-blue-500 border  py-1.5 px-3 rounded-md text-blue-600 font-medium'
                      }
                    >
                      {file.name}
                    </sapn>
                  )}
                  <button
                    title="Save"
                    onClick={() => handleFileUpload()}
                    disabled={!file}
                    className="w-fit disabled:cursor-not-allowed  bg-blue-500 disabled:hover:bg-blue-500  hover:bg-blue-600 text-white font-bold py-2 px-3 rounded-lg transition duration-300 disabled:opacity-50 flex items-center justify-center shadow-md hover:shadow-lg cursor-pointer"
                  >
                    <ArrowDownCircle className="stroke-white -rotate-90" />
                  </button>
                </div>
              </div>

              {isSummaryBtn && (
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={generateSummary}
                  disabled={!isSummaryBtn}
                  className="w-fit mx-auto  bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50"
                >
                  {isLoading ? (
                    <motion.div
                      animate={{ rotate: 360 }}
                      transition={{
                        duration: 1,
                        repeat: Infinity,
                        ease: 'linear',
                      }}
                    >
                      <RefreshCw size={20} className="mr-2" />
                    </motion.div>
                  ) : (
                    <Activity size={20} className="mr-2" />
                  )}
                  {isLoading
                    ? `${t('medicalScribing.generateInsights')}...`
                    : `${t('medicalScribing.generateInsights')}`}
                </motion.button>
              )}
            </div>
          </motion.div>
        </div>

        {isSummarySection && (
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-9 border-b border-gray-300 w-fit">
              {TAB_Data?.map(({ id, name, value }) => (
                <button
                  key={id}
                  onClick={() => setSelectedTab(value)}
                  className={`${
                    selectedTab === value
                      ? 'border-[#005CFF] !text-[#005CFF]'
                      : 'border-transparent'
                  } font-poppins text-base font-medium text-[#989898] pb-2 border-b-2  m-[-1px]`}
                >
                  {t(name)}
                </button>
              ))}
            </div>
            <>
              {selectedTab === 'Transcript' && transcriptResponse && (
                <TranscriptTab TranscriptData={transcriptResponse} />
              )}
              {selectedTab === 'EHR Summary' && (
                <EhrSummaryTab data={reportResponse} />
              )}
              {selectedTab === 'PHI' && <PhiTab />}
              {selectedTab === 'ICD Code Breakdown' && icd10Response && (
                <IcdTab IcdData={icd10Response} />
              )}
              {/* {selectedTab === "Masked Data" && <MaskedTab />}
              {selectedTab === "Rx_norm" && <RxNormTab />} */}
            </>
          </div>
        )}

        {/* <Breakdown /> */}
      </div>

      {isFileLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center backdrop-blur-sm">
          <span className="loader" />
        </div>
      )}
    </>
  );
};

export default MedicalScribing;
