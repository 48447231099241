import React, { useState, useRef, useEffect } from "react";
import {
  FileText,
  Upload,
  Mic,
  Loader,
  AlertCircle,
  Save,
  Share2,
  Calendar,
  Users,
  Clock,
  ChevronRight,
  Check,
  X,
  BarChart2,
  List,
  MessageCircle,
  Settings,
  PlusCircle,
} from "react-feather";
import { motion, AnimatePresence } from "framer-motion";

const MeetingSummarizer = () => {
  const [file, setFile] = useState(null);
  const [summary, setSummary] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);
  const [recentFiles, setRecentFiles] = useState([
    { name: "Team Meeting 2024-07-10.mp3", date: "2024-07-10" },
    { name: "Board Presentation.pdf", date: "2024-07-08" },
    { name: "Project Kickoff.txt", date: "2024-07-05" },
  ]);
  const [showSettings, setShowSettings] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setStep(1);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    setFile(e.dataTransfer.files[0]);
    setStep(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError("");
    try {
      for (let i = 0; i <= 100; i += 10) {
        await new Promise((resolve) => setTimeout(resolve, 300));
        setProgress(i);
      }
      setSummary({
        overview: "This is a high-level overview of the meeting...",
        keyPoints: [
          "Discussed Q2 financial results",
          "Approved new marketing strategy",
          "Set timeline for product launch",
        ],
        actionItems: [
          {
            task: "Prepare Q2 report",
            assignee: "John Doe",
            deadline: "2024-07-20",
          },
          {
            task: "Finalize marketing budget",
            assignee: "Jane Smith",
            deadline: "2024-07-25",
          },
          {
            task: "Schedule product launch event",
            assignee: "Mike Johnson",
            deadline: "2024-08-01",
          },
        ],
        sentimentAnalysis: {
          overall: "Positive",
          breakdown: { positive: 70, neutral: 20, negative: 10 },
        },
      });
      setStep(2);
    } catch (err) {
      setError(
        "An error occurred while summarizing the meeting. Please try again."
      );
    } finally {
      setIsProcessing(false);
      setProgress(0);
    }
  };

  const toggleRecording = () => {
    setIsRecording(!isRecording);
    if (!isRecording) setStep(1);
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return "📄";
      case "doc":
      case "docx":
        return "📝";
      case "mp3":
      case "wav":
        return "🎵";
      default:
        return "📁";
    }
  };

  const saveOrShareSummary = () => {
    // Implement save or share functionality
  };

  const renderStepIndicator = () => {
    const steps = ["Upload", "Process", "Summary"];
    return (
      <div className="flex justify-between mb-4">
        {steps.map((stepName, index) => (
          <div
            key={index}
            className={`flex items-center ${
              index <= step ? "text-blue-500" : "text-gray-400"
            }`}
          >
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                index <= step ? "bg-blue-500" : "bg-gray-200"
              }`}
            >
              {index < step ? (
                <Check size={16} className="text-white" />
              ) : (
                <span className="text-white">{index + 1}</span>
              )}
            </div>
            <span className="ml-2">{stepName}</span>
            {index < steps.length - 1 && (
              <ChevronRight size={20} className="mx-2" />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="mx-auto px-4 py-8 space-y-8 font-poppins bg-gradient-to-br from-gray-50 to-blue-50 dark:from-gray-900 dark:to-blue-900 min-h-screen transition-colors duration-300">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold mb-8 text-gray-800 dark:text-white flex items-center"
      >
        <FileText className="mr-4 text-blue-500" size={36} />
        Meeting Summarizer
      </motion.h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700 hover:shadow-xl transition-all duration-300"
        >
          <div className="p-6 space-y-6">
            {renderStepIndicator()}
            <form onSubmit={handleSubmit} className="space-y-6">
              <AnimatePresence mode="wait">
                {step === 0 && (
                  <motion.div
                    key="upload"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    className={`border-2 border-dashed rounded-lg p-8 text-center transition-all duration-300 ${
                      dragging
                        ? "border-blue-500 bg-blue-50 dark:bg-blue-900"
                        : "border-gray-300 dark:border-gray-600"
                    }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current.click()}
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      className="hidden"
                      accept=".txt,.doc,.docx,.pdf,.mp3,.wav"
                    />
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="flex flex-col items-center space-y-2 cursor-pointer"
                    >
                      <Upload size={48} className="text-blue-400" />
                      <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                        Drag and drop or click to upload meeting transcript or
                        audio file
                      </span>
                    </motion.div>
                  </motion.div>
                )}
                {step === 1 && (
                  <motion.div
                    key="file-info"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg"
                  >
                    <div className="flex items-center space-x-2">
                      <span className="text-2xl">{getFileIcon(file.name)}</span>
                      <span className="font-medium text-gray-700 dark:text-gray-200">
                        {file.name}
                      </span>
                      <Check className="text-green-500 ml-auto" size={20} />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="flex items-center justify-between">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={toggleRecording}
                  className={`relative w-16 h-16 rounded-full flex items-center justify-center shadow-md hover:shadow-lg transition-all duration-300 ${
                    isRecording ? "bg-red-500" : "bg-blue-500"
                  }`}
                >
                  <Mic
                    size={24}
                    className={`text-white ${
                      isRecording ? "animate-pulse" : ""
                    }`}
                  />
                  {isRecording && (
                    <motion.div
                      className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full"
                      animate={{ scale: [1, 1.2, 1] }}
                      transition={{ duration: 1, repeat: Infinity }}
                    />
                  )}
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  disabled={isProcessing || (!file && !isRecording)}
                  className="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50"
                >
                  {isProcessing ? (
                    <Loader size={20} className="animate-spin mr-2" />
                  ) : (
                    <FileText size={20} className="mr-2" />
                  )}
                  {isProcessing ? "Processing..." : "Generate Summary"}
                </motion.button>
              </div>
              {isProcessing && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700"
                >
                  <motion.div
                    className="bg-blue-600 h-2.5 rounded-full"
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    transition={{ duration: 0.5 }}
                  ></motion.div>
                </motion.div>
              )}
            </form>
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4 text-gray-700 dark:text-gray-300">
                Recent Files
              </h3>
              <ul className="space-y-2">
                {recentFiles.map((file, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between p-2 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <span className="text-2xl mr-2">
                        {getFileIcon(file.name)}
                      </span>
                      <span className="text-sm text-gray-700 dark:text-gray-300">
                        {file.name}
                      </span>
                    </div>
                    <span className="text-xs text-gray-500 dark:text-gray-400">
                      {file.date}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex justify-between mt-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg"
              >
                <PlusCircle size={16} className="mr-2" />
                New Meeting
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowSettings(!showSettings)}
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg"
              >
                <Settings size={16} className="mr-2" />
                Settings
              </motion.button>
            </div>
            <AnimatePresence>
              {showSettings && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="mt-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg"
                >
                  <h4 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">
                    Preferences
                  </h4>
                  {/* Add settings options here */}
                  <div className="space-y-2">
                    <label className="flex items-center">
                      <input type="checkbox" className="mr-2" />
                      <span className="text-sm text-gray-700 dark:text-gray-300">
                        Enable dark mode
                      </span>
                    </label>
                    <label className="flex items-center">
                      <input type="checkbox" className="mr-2" />
                      <span className="text-sm text-gray-700 dark:text-gray-300">
                        Auto-save summaries
                      </span>
                    </label>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
        <AnimatePresence>
          {summary && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.5 }}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700 hover:shadow-xl transition-all duration-300"
            >
              <div className="p-6 space-y-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
                    Meeting Summary
                  </h2>
                  <div className="flex space-x-2">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={saveOrShareSummary}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg"
                    >
                      <Save size={16} className="mr-2" />
                      Save
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={saveOrShareSummary}
                      className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg"
                    >
                      <Share2 size={16} className="mr-2" />
                      Share
                    </motion.button>
                  </div>
                </div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg space-y-2"
                >
                  <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                    <Calendar size={16} className="mr-2" />
                    <span>Date: July 11, 2024</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                    <Users size={16} className="mr-2" />
                    <span>
                      Participants: John Doe, Jane Smith, Mike Johnson
                    </span>
                  </div>
                  <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                    <Clock size={16} className="mr-2" />
                    <span>Duration: 1 hour 30 minutes</span>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-4"
                >
                  <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-2 flex items-center">
                      <MessageCircle size={20} className="mr-2 text-blue-500" />
                      Overview
                    </h3>
                    <p className="text-gray-700 dark:text-gray-300">
                      {summary.overview}
                    </p>
                  </div>
                  <div className="bg-indigo-50 dark:bg-indigo-900 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-2 flex items-center">
                      <List size={20} className="mr-2 text-indigo-500" />
                      Key Points
                    </h3>
                    <ul className="list-disc list-inside text-gray-700 dark:text-gray-300">
                      {summary.keyPoints.map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="bg-green-50 dark:bg-green-900 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-2 flex items-center">
                      <Check size={20} className="mr-2 text-green-500" />
                      Action Items
                    </h3>
                    <ul className="space-y-2">
                      {summary.actionItems.map((item, index) => (
                        <li
                          key={index}
                          className="flex items-center justify-between"
                        >
                          <span className="text-gray-700 dark:text-gray-300">
                            {item.task}
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {item.assignee} - {item.deadline}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="bg-yellow-50 dark:bg-yellow-900 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-2 flex items-center">
                      <BarChart2 size={20} className="mr-2 text-yellow-500" />
                      Sentiment Analysis
                    </h3>
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-gray-700 dark:text-gray-300">
                        Overall Sentiment:
                      </span>
                      <span className="font-semibold text-green-500">
                        {summary.sentimentAnalysis.overall}
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div
                        className="bg-green-500 h-2.5 rounded-full"
                        style={{
                          width: `${summary.sentimentAnalysis.breakdown.positive}%`,
                        }}
                      ></div>
                    </div>
                    <div className="flex justify-between text-sm mt-1">
                      <span className="text-green-500">
                        Positive: {summary.sentimentAnalysis.breakdown.positive}
                        %
                      </span>
                      <span className="text-gray-500">
                        Neutral: {summary.sentimentAnalysis.breakdown.neutral}%
                      </span>
                      <span className="text-red-500">
                        Negative: {summary.sentimentAnalysis.breakdown.negative}
                        %
                      </span>
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg mt-4 flex items-center"
          >
            <AlertCircle size={20} className="mr-2" />
            {error}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MeetingSummarizer;