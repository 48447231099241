import React, { useContext, useState } from 'react';
import { ThemeContext } from './ThemeProvider';
import { Search, Bell, Settings, ChevronDown, File, MoreHorizontal, ExternalLink } from 'react-feather';

const Reports = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [activeMenuItem, setActiveMenuItem] = useState('Dashboard');

  const themeClass = isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800';

  const MenuItem = ({ icon, text, isActive, hasSubmenu }) => (
    <div 
      className={`flex items-center py-2 mx-2 px-4 rounded-lg cursor-pointer ${
        isActive ? 'bg-gray-200 dark:bg-gray-700' : 'hover:bg-gray-100 dark:hover:bg-gray-800'
      }`}
      onClick={() => setActiveMenuItem(text)}
    >
      <span className="mr-3 text-gray-500 dark:text-gray-400">{icon}</span>
      <span className="flex-grow text-sm">{text}</span>
      {hasSubmenu && <ChevronDown size={14} className="text-gray-400 dark:text-gray-500" />}
    </div>
  );

  return (
    <div className={`flex h-screen ${themeClass}`}>
      {/* Left Panel */}
      <div className="w-52 border-r border-gray-200 dark:border-gray-700 py-6 overflow-y-auto">
        <h1 className="text-xl font-bold mb-8 px-6">Reports</h1>
        
        <MenuItem icon="☰" text="Dashboard" isActive={activeMenuItem === 'Dashboard'} />
        <MenuItem icon="📊" text="All Metrics" isActive={activeMenuItem === 'All Metrics'} />
        <MenuItem icon="👤" text="For you" isActive={activeMenuItem === 'For you'} />
        <MenuItem icon="♡" text="My Favorites" isActive={activeMenuItem === 'My Favorites'} />
        
        <div className="mt-8">
          <h2 className="text-xs uppercase text-gray-400 dark:text-gray-500 font-semibold mb-3 px-6">DATA SOURCES</h2>
          <MenuItem icon="👥" text="Human Support" hasSubmenu />
        </div>
      </div>

      {/* Right Content */}
      <div className="flex-grow overflow-y-auto">
        <header className="flex justify-between items-center px-8 py-6 border-b border-gray-200 dark:border-gray-700">
          <h1 className="text-2xl font-semibold">Hi there, Adam 👋</h1>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search for metrics"
                className="pl-10 pr-4 py-2 rounded-lg bg-gray-100 dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 w-64"
              />
              <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
            </div>
            <button className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700">
              <Bell size={20} />
            </button>
            <button className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700">
              <Settings size={20} />
            </button>
          </div>
        </header>

        <main className="p-8">
          <div className="mb-8">
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">Monday, 23 February</p>
            <h2 className="text-xl font-semibold mb-4">Total balance</h2>
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex items-center mb-4 lg:mb-0">
                <span className="text-4xl font-bold">$150,958,000.99</span>
                <span className="ml-4 text-green-500 text-sm">+10% since last month</span>
              </div>
              <div className="flex items-center space-x-3">
                <button className="px-4 py-2 bg-gray-100 dark:bg-gray-800 rounded-md flex items-center text-sm">
                  Monthly <ChevronDown size={16} className="ml-2" />
                </button>
                <button className="px-4 py-2 bg-gray-100 dark:bg-gray-800 rounded-md flex items-center text-sm">
                  Export <ChevronDown size={16} className="ml-2" />
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
            {[
              { title: 'Sales', value: '$6,678.67' },
              { title: 'Orders', value: '67' },
              { title: 'Avg. order value', value: '$178.45' }
            ].map((metric, index) => (
              <div key={index} className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg">
                <h3 className="text-lg font-semibold mb-3">{metric.title}</h3>
                <div className="text-3xl font-bold mb-2">{metric.value}</div>
                <div className="text-green-500 text-sm">+10% since last month</div>
              </div>
            ))}
          </div>

          <div className="mb-12">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Projects you're working on</h2>
              <a href="#" className="text-blue-500 hover:underline text-sm">See all</a>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                { title: 'B2B Discovery Interview Q2', update: '1h ago' },
                { title: 'Moderated Usability Test', update: 'yesterday', collaborators: 3 },
                { title: 'B2B Discovery Interview Q1', update: '02/09/22' }
              ].map((project, index) => (
                <div key={index} className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg">
                  <File className="mb-4 text-gray-400 dark:text-gray-500" size={24} />
                  <h3 className="font-semibold mb-2">{project.title}</h3>
                  <div className="flex justify-between items-center">
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      Updated {project.update}
                    </div>
                    {project.collaborators && (
                      <div className="flex -space-x-2">
                        {[...Array(project.collaborators)].map((_, i) => (
                          <div key={i} className="w-6 h-6 rounded-full bg-gray-300 dark:bg-gray-600 border-2 border-white dark:border-gray-800" />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div>
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">Recent Conversations</h2>
                <a href="#" className="text-blue-500 hover:underline text-sm">See all</a>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="text-left text-gray-500 dark:text-gray-400 text-sm">
                      <th className="pb-4 font-normal">Name</th>
                      <th className="pb-4 font-normal">Role</th>
                      <th className="pb-4 font-normal">Interview date</th>
                      <th className="pb-4 font-normal"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(2)].map((_, index) => (
                      <tr key={index} className="border-t border-gray-200 dark:border-gray-700">
                        <td className="py-4 flex items-center">
                          <div className="w-8 h-8 rounded-full bg-gray-300 dark:bg-gray-600 mr-3" />
                          Jack Smith
                        </td>
                        <td className="py-4 text-sm text-gray-500 dark:text-gray-400">Product Manager</td>
                        <td className="py-4 text-sm text-gray-500 dark:text-gray-400">Feb 19, 2022</td>
                        <td className="py-4"><MoreHorizontal size={20} className="text-gray-400 dark:text-gray-500" /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <h2 className="text-xl font-semibold mb-6">Upcoming Meetings</h2>
              {[...Array(2)].map((_, index) => (
                <div key={index} className="flex items-start mb-6">
                  <div className="w-12 text-center mr-4">
                    <div className="text-2xl font-bold">22</div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">Feb</div>
                  </div>
                  <div className="flex-grow">
                    <h3 className="font-semibold mb-1">UX Interview x Laura Hill</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">2:00 PM - 2:30 PM</p>
                    <a href="#" className="text-blue-500 hover:underline flex items-center text-sm">
                      <ExternalLink size={14} className="mr-1" />
                      meet.google.com/mbv-adfa-pig
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Reports;