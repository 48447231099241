import React, { useState } from 'react';
import ScribingTranscriptTab from './ScribingTranscriptTab';
import ScribingEHRSummaryTab from './ScribingEHRSummaryTab';
import ScribingMaskedDataTab from './ScribingMaskedDataTab';
import ScribingICDBreakdownTab from './ScribingICDBreakdownTab';
import HeaderSettingIcon from '../../assets/icons/HeaderSettingIcon';
import AudioRecording from './AudioRecording/AudioRecording';

const TAB_DATA = [
  { id: 1, name: 'Transcript', value: 'TRANSCRIPT' },
  { id: 2, name: 'EHR Summary', value: 'EHR_SUMMARY' },
  { id: 3, name: 'Masked Data', value: 'MASKED_DATA' },
  { id: 4, name: 'ICD Code Breakdown', value: 'ICD_CODE_BREAKDOWN' },
];

const MedicalScribingNew = () => {
  const [selectedTab, setSelectedTab] = useState('TRANSCRIPT');
  const [file, setFile] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [uploadedFileResponse, setUploadedFileResponse] = useState(null);

  console.log('uploadedFileResponse >>>>', uploadedFileResponse);

  return (
    <div className="h-screen overflow-y-auto bg-[#fcfcfc] flex flex-col gap-6 px-6">
      <h1 className="font-poppins font-medium text-sm leading-5 tracking-[-2%] py-5">
        MEDICAL SCRIBING
      </h1>

      {/* <div className="grid grid-cols-2 gap-5"> */}
      <div className="gap-5 max-w-[800px] w-full mx-auto">
        {/* <PatientInformation /> */}
        <AudioRecording
          initialFile={file}
          setFile={(newFile) => setFile(newFile)}
          setIsFileLoading={setIsFileLoading}
          setUploadedFileResponse={setUploadedFileResponse}
        />
      </div>

      <div className="flex flex-col gap-9 py-6 px-9">
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-9 border-b border-gray-300 w-fit">
            {TAB_DATA?.map(({ id, name, value }) => (
              <button
                key={id}
                onClick={() => setSelectedTab(value)}
                className={`${
                  selectedTab === value
                    ? 'border-[#005CFF] !text-[#005CFF]'
                    : 'border-transparent'
                } font-poppins text-base font-medium h-full text-[#989898] pb-2 border-b-2  m-[-1px]`}
              >
                {name}
              </button>
            ))}
          </div>

          <button>
            <HeaderSettingIcon />
          </button>
        </div>
        <>
          {selectedTab === 'TRANSCRIPT' && <ScribingTranscriptTab />}

          {selectedTab === 'EHR_SUMMARY' && <ScribingEHRSummaryTab />}

          {selectedTab === 'MASKED_DATA' && <ScribingMaskedDataTab />}

          {selectedTab === 'ICD_CODE_BREAKDOWN' && <ScribingICDBreakdownTab />}
        </>
      </div>

      {isFileLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center backdrop-blur-sm">
          <span className="loader" />
        </div>
      )}
    </div>
  );
};

export default MedicalScribingNew;
