import React, { useState } from "react";

const EnterpriceSearch = () => {
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    fileType: "all",
    dateRange: "all",
  });
  const [results, setResults] = useState([]);

  const handleSearch = () => {
    // Placeholder for search logic
    setResults([
      {
        id: 1,
        title: "Enterprise Search in AI",
        description:
          "A comprehensive guide to implementing enterprise search solutions.",
        fileType: "PDF",
        relevanceScore: 95,
        date: "2024-08-15",
      },
      {
        id: 2,
        title: "AI-powered Search Algorithms",
        description:
          "A technical paper on the impact of AI on search efficiency.",
        fileType: "DOCX",
        relevanceScore: 89,
        date: "2024-07-10",
      },
    ]);
  };

  const handleReset = () => {
    setQuery("");
    setFilters({
      fileType: "all",
      dateRange: "all",
    });
    setResults([]);
  };
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col">
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Search Your Enterprise Data
          </h2>
          <p className="text-gray-600 mb-4">
            Find relevant documents, reports, and data using AI-powered search.
          </p>

          {/* Search Bar */}
          <div className="mb-6 flex space-x-4">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Enter search query"
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={handleSearch}
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition-transform transform hover:scale-105"
            >
              Search
            </button>
          </div>

          {/* Filter Options */}
          <div className="mb-6 grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-xl">
            <div>
              <label
                htmlFor="fileType"
                className="block text-sm font-medium text-gray-700"
              >
                File Type:
              </label>
              <select
                id="fileType"
                value={filters.fileType}
                onChange={(e) =>
                  setFilters({ ...filters, fileType: e.target.value })
                }
                className="w-full mt-2 p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="all">All</option>
                <option value="pdf">PDF</option>
                <option value="docx">DOCX</option>
                <option value="txt">Text</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="dateRange"
                className="block text-sm font-medium text-gray-700"
              >
                Date Range:
              </label>
              <select
                id="dateRange"
                value={filters.dateRange}
                onChange={(e) =>
                  setFilters({ ...filters, dateRange: e.target.value })
                }
                className="w-full mt-2 p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="all">All Time</option>
                <option value="last7">Last 7 Days</option>
                <option value="last30">Last 30 Days</option>
                <option value="lastYear">Last Year</option>
              </select>
            </div>
          </div>

          {/* Search Results */}
          {results.length > 0 && (
            <div className="mt-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Search Results
              </h3>
              <ul className="space-y-4">
                {results.map((result) => (
                  <li
                    key={result.id}
                    className="bg-gray-100 p-4 rounded-lg shadow"
                  >
                    <h4 className="text-lg font-semibold text-gray-900">
                      {result.title}
                    </h4>
                    <p className="text-gray-700 mb-2">{result.description}</p>
                    <p className="text-sm text-gray-600">
                      <strong>File Type:</strong> {result.fileType} |{" "}
                      <strong>Date:</strong> {result.date} |{" "}
                      <strong>Relevance:</strong> {result.relevanceScore}%
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Reset Button */}
          {results.length > 0 && (
            <div className="mt-6">
              <button
                onClick={handleReset}
                className="bg-gray-300 text-gray-700 py-2 px-6 rounded-lg shadow-lg hover:bg-gray-400 transition-transform transform hover:scale-105"
              >
                Reset Search
              </button>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default EnterpriceSearch;
