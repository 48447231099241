import React, { useState } from "react";

const settlmentData = [
  {
    id: "S123456",
    name: "John",
    amount: "$5,000.00",
    date: "2024-09-15",
    status: "Pending",
    details: {
      transactionId: "T987654",
      paymentMethod: "Bank Transfer",
      referenceNumber: "R123456789",
      notes: "Payment pending approval.",
    },
  },
  {
    id: "S123457",
    name: "David",
    amount: "$12,350.00",
    date: "2024-09-16",
    status: "Completed",
    details: {
      transactionId: "T987655",
      paymentMethod: "Credit Card",
      referenceNumber: "R987654321",
      notes: "Payment completed successfully.",
    },
  },
  {
    id: "S123458",
    name: "Michel",
    amount: "$8,750.00",
    date: "2024-09-17",
    status: "Failed",
    details: {
      transactionId: "T987656",
      paymentMethod: "PayPal",
      referenceNumber: "R112233445",
      notes: "Payment failed due to insufficient funds.",
    },
  },
  {
    id: "S123459",
    name: "Warner",
    amount: "$3,200.00",
    date: "2024-09-18",
    status: "Pending",
    details: {
      transactionId: "T987657",
      paymentMethod: "Bank Transfer",
      referenceNumber: "R556677889",
      notes: "Awaiting confirmation from the bank.",
    },
  },
];

const SettlementProcessing = () => {
  const [settlements, setSettlements] = useState(settlmentData);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSettlement, setSelectedSettlement] = useState(null);

  const handleInitiateProcessing = (settlementId) => {
    // Implement processing initiation logic
    console.log("Processing initiated for:", settlementId);
  };

  const handleViewDetails = (settlement) => {
    setSelectedSettlement(settlement);
  };

  const handleCloseDetails = () => {
    setSelectedSettlement(null);
  };

  const filteredSettlements = settlements.filter(
    (settlement) =>
      settlement.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      settlement.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col">
      {/* Main Content */}
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Settlements Processing
          </h2>
          <p className="text-gray-600 mb-4">
            Manage and process your financial settlements efficiently.
          </p>

          {/* Search Section */}
          <div className="mb-6">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search settlements..."
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Settlement Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 bg-white shadow-md rounded-lg">
              <thead>
                <tr>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredSettlements.length > 0 ? (
                  filteredSettlements.map((settlement, index) => (
                    <tr key={index}>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {settlement.id}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {settlement.name}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {settlement.amount}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        {settlement.date}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500">
                        <button
                          onClick={() => handleViewDetails(settlement)}
                          className="text-blue-500 hover:text-blue-600 font-medium mr-4"
                        >
                          View Details
                        </button>
                        <button
                          onClick={() =>
                            handleInitiateProcessing(settlement.id)
                          }
                          className="bg-green-500 hover:bg-green-600 text-white py-1 px-4 rounded-lg"
                        >
                          Process
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className="py-4 px-6 text-center text-gray-500"
                    >
                      No settlements found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        {/* Details Modal */}
        {selectedSettlement && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg mx-auto">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Settlement Details
              </h3>
              <p className="text-gray-700 mb-2">
                <strong>ID:</strong> {selectedSettlement.id}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>Name:</strong> {selectedSettlement.name}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>Amount:</strong> {selectedSettlement.amount}
              </p>
              <p className="text-gray-700 mb-4">
                <strong>Date:</strong> {selectedSettlement.date}
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleCloseDetails}
                  className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default SettlementProcessing;
