import React, { useEffect, useState } from "react";

const LiveTranscription = () => {
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [partialTranscript, setPartialTranscript] = useState("");
  const [audioContext, setAudioContext] = useState(null);
  const [mediaStreamSource, setMediaStreamSource] = useState(null);
  const [processor, setProcessor] = useState(null);

  const BUFFER_SIZE = 16384;
  const serverUrl = "http://54.85.146.122:5000/";

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.0.1/socket.io.js";
    script.onload = () => {
      window.socket = window.io(serverUrl, {
        withCredentials: true,
        transports: ["websocket"],
      });

      window.socket.on("transcription", (data) => {
        updateTranscription(data.text.trim(), data.is_partial);
      });

      window.socket.on("connect", () => {
        console.log("Connected to server");
      });

      window.socket.on("connect_error", (error) => {
        console.error("Connection error:", error);
      });

      window.socket.on("disconnect", () => {
        console.log("Disconnected from server");
        setIsTranscribing(false);
      });

      window.socket.on("error", (error) => {
        console.error("Socket.IO Error:", error);
      });
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup script and close audioContext if it exists
      document.body.removeChild(script);
      if (audioContext) {
        audioContext.close();
      }
    };
  }, [audioContext]);

  const startTranscription = async () => {
    if (isTranscribing) return;
    setIsTranscribing(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const mediaStreamSrc = audioCtx.createMediaStreamSource(stream);
      const audioProcessor = audioCtx.createScriptProcessor(BUFFER_SIZE, 1, 1);

      mediaStreamSrc.connect(audioProcessor);
      audioProcessor.connect(audioCtx.destination);

      audioProcessor.onaudioprocess = (e) => {
        // debugger;
        //if (isTranscribing) {
        const inputData = e.inputBuffer.getChannelData(0);
        const pcmData = convertFloatTo16BitPCM(inputData);
        sendAudioChunk(pcmData);
        // }
      };

      window.socket.emit("start_transcription", (response) => {
        console.log(response.status);
        if (response.status === "Transcription started") {
          setIsTranscribing(true);
        }
      });

      setAudioContext(audioCtx);
      setMediaStreamSource(mediaStreamSrc);
      setProcessor(audioProcessor);
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const stopTranscription = () => {
    if (!isTranscribing) return;

    setIsTranscribing(false);
    if (processor) {
      processor.disconnect();
      mediaStreamSource.disconnect();
      audioContext.close();
    }

    window.socket.emit("stop_transcription", (response) => {
      console.log(response.status);
    });
  };

  const convertFloatTo16BitPCM = (float32Array) => {
    const int16Array = new Int16Array(float32Array.length);
    for (let i = 0; i < float32Array.length; i++) {
      const s = Math.max(-1, Math.min(1, float32Array[i]));
      int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }
    return int16Array;
  };

  const sendAudioChunk = (audioData) => {
    window.socket.emit("audio_data", { audio: audioData.buffer });
    console.log(
      `Sent audio chunk of size ${audioData.length} samples (${
        audioData.length * 2
      } bytes)`
    );
  };

  let fullTranscript = "";
  let lastPartialLength = 0;

  const updateTranscription = (text, isPartial) => {
    if (isPartial) {
      fullTranscript = fullTranscript.slice(0, -lastPartialLength);
      fullTranscript += text;
      lastPartialLength = text.length;
      setPartialTranscript(text);
    } else {
      fullTranscript = fullTranscript.slice(0, -lastPartialLength);
      if (fullTranscript && !fullTranscript.endsWith(" ")) {
        fullTranscript += " ";
      }
      fullTranscript += text;
      lastPartialLength = 0;
      setPartialTranscript("");
    }
    setTranscription(fullTranscript);
  };

  useEffect(() => {}, [isTranscribing]);

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <h1>Live Transcription</h1>
      <div className="flex gap-1">
        <button
          className="py-2  rounded-lg px-3 font-poppins flex items-center hover:bg-blue-45 duration-300 text-white text-base font-medium bg-blue-20"
          onClick={startTranscription}
          disabled={isTranscribing}
        >
          Start Transcription
        </button>
        <button
          onClick={stopTranscription}
          disabled={!isTranscribing}
          className="py-2  rounded-lg px-3 font-poppins flex items-center hover:bg-blue-45 duration-300 text-white text-base font-medium bg-blue-20"
        >
          Stop Transcription
        </button>
      </div>
      <div
        id="transcription"
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          minHeight: "200px",
          marginTop: "20px",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
      >
        {transcription}
      </div>
      <div
        id="partialTranscript"
        style={{ color: "#888", fontStyle: "italic", marginTop: "10px" }}
      >
        {partialTranscript}
      </div>
    </div>
  );
};

export default LiveTranscription;
