import React, { useState } from "react";
import {
  GitMerge,
  Upload,
  Download,
  Loader,
  AlertCircle,
  X,
  Settings,
  Clock,
  ChevronLeft,
  ChevronRight,
} from "react-feather";
import { motion, AnimatePresence } from "framer-motion";

const Step = ({ number, title, isActive, isCompleted, onClick }) => (
  <motion.div
    onClick={onClick}
    className={`flex items-center space-x-2 cursor-pointer ${
      isActive
        ? "text-indigo-600 dark:text-indigo-400"
        : "text-gray-500 dark:text-gray-400"
    }`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div
      className={`w-8 h-8 rounded-full flex items-center justify-center ${
        isCompleted
          ? "bg-indigo-600 text-white"
          : isActive
          ? "bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-400"
          : "bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-400"
      }`}
    >
      {isCompleted ? "✓" : number}
    </div>
    <span className="text-sm font-medium">{title}</span>
  </motion.div>
);

const EntityResolution = () => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [resolvedEntities, setResolvedEntities] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
    if (files.length === 0 && e.target.files.length > 0) {
      setStep(2);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles([...files, ...droppedFiles]);
    if (files.length === 0 && droppedFiles.length > 0) {
      setStep(2);
    }
  };

  const handleResolve = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError("");
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setResolvedEntities([
        { id: 1, name: "John Doe", sources: ["File1.csv", "File2.csv"] },
        { id: 2, name: "Jane Smith", sources: ["File1.csv", "File3.csv"] },
      ]);
      setStep(3);
    } catch (err) {
      setError("An error occurred during entity resolution. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const removeFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setStep(1);
    }
  };

  const goToStep = (newStep) => {
    if (
      newStep >= 1 &&
      newStep <= 3 &&
      newStep <= Math.max(step, files.length > 0 ? 2 : 1)
    ) {
      setStep(newStep);
    }
  };

  return (
    <div className="max-w-7xl font-poppins mx-auto px-4 py-8 space-y-8">
      <h1 className="text-4xl font-bold mb-8 text-gray-800 dark:text-white">
        Entity Resolution
      </h1>
      <div className="flex justify-between items-center mb-8">
        <div className="flex space-x-4">
          <Step
            number={1}
            title="Upload Files"
            isActive={step === 1}
            isCompleted={step > 1}
            onClick={() => goToStep(1)}
          />
          <ChevronRight className="text-gray-400 dark:text-gray-600" />
          <Step
            number={2}
            title="Review Files"
            isActive={step === 2}
            isCompleted={step > 2}
            onClick={() => goToStep(2)}
          />
          <ChevronRight className="text-gray-400 dark:text-gray-600" />
          <Step
            number={3}
            title="Results"
            isActive={step === 3}
            isCompleted={false}
            onClick={() => goToStep(3)}
          />
        </div>
        <div className="flex space-x-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="p-2 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          >
            <Settings size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="p-2 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          >
            <Clock size={20} />
          </motion.button>
        </div>
      </div>
      <AnimatePresence mode="wait">
        {step === 1 && (
          <motion.div
            key="step1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden"
          >
            <div
              className="p-6 space-y-6"
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-indigo-300 border-dashed rounded-lg cursor-pointer bg-indigo-50 dark:bg-gray-700 hover:bg-indigo-100 dark:hover:bg-gray-600 dark:border-indigo-600 dark:hover:border-indigo-500 transition-colors"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Upload className="w-10 h-10 mb-3 text-indigo-500 dark:text-indigo-400" />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      CSV, JSON, XML (MAX. 50MB each)
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    accept=".csv,.json,.xml"
                    multiple
                  />
                </label>
              </div>
            </div>
          </motion.div>
        )}
        {step === 2 && (
          <motion.div
            key="step2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden"
          >
            <div className="p-6 space-y-6">
              <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-white">
                Uploaded Files:
              </h3>
              <ul className="space-y-2">
                {files.map((file, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between bg-indigo-50 dark:bg-gray-700 p-3 rounded-lg"
                  >
                    <div className="flex items-center space-x-3">
                      <div className="bg-indigo-100 dark:bg-gray-600 p-2 rounded-full">
                        <Upload className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />
                      </div>
                      <div>
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          {file.name}
                        </span>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          {(file.size / 1024 / 1024).toFixed(2)} MB
                        </p>
                      </div>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                    >
                      <X size={16} />
                    </motion.button>
                  </li>
                ))}
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleResolve}
                disabled={isProcessing || files.length === 0}
                className="w-full bg-gradient-to-r from-indigo-500 to-indigo-600 hover:from-indigo-600 hover:to-indigo-700 text-white font-bold py-3 px-4 rounded-lg transition duration-200 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50"
              >
                {isProcessing ? (
                  <Loader size={20} className="animate-spin mr-2" />
                ) : (
                  <GitMerge size={20} className="mr-2" />
                )}
                {isProcessing ? "Resolving..." : "Resolve Entities"}
              </motion.button>
            </div>
          </motion.div>
        )}
        {step === 3 && resolvedEntities && (
          <motion.div
            key="step3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden"
          >
            <div className="p-6">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
                Resolved Entities:
              </h2>
              <div className="bg-indigo-50 dark:bg-gray-700 p-4 rounded-lg shadow-inner">
                <ul className="space-y-4">
                  {resolvedEntities.map((entity) => (
                    <li
                      key={entity.id}
                      className="border-b border-indigo-200 dark:border-gray-600 pb-2"
                    >
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                        {entity.name}
                      </h3>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Sources: {entity.sources.join(", ")}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-4 flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-200"
              >
                <Download size={20} className="mr-2" />
                Download Resolved Entities
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {error && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg mt-4 flex items-center"
        >
          <AlertCircle size={20} className="mr-2" />
          {error}
        </motion.div>
      )}
    </div>
  );
};

export default EntityResolution;
