import React, { useState } from "react";

const ImageEnhancement = () => {
  const [image, setImage] = useState(null);
  const [enhancedImage, setEnhancedImage] = useState(null);
  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEnhanceImage = () => {
    // Placeholder logic for image enhancement, such as applying filters
    setEnhancedImage(image); // This would be replaced by actual enhancement logic
  };

  const handleReset = () => {
    setImage(null);
    setEnhancedImage(null);
    setBrightness(100);
    setContrast(100);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col w-full">
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Enhance Your Image with AI
          </h2>
          <p className="text-gray-600 mb-4">
            Upload an image, adjust enhancement settings, and download the
            processed image.
          </p>

          <div className="mb-6 max-w-xl">
            <label
              htmlFor="upload"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Upload Your Image:
            </label>
            <input
              type="file"
              id="upload"
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {image && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                Enhancement Settings
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                <div>
                  <label
                    htmlFor="brightness"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Brightness: {brightness}%
                  </label>
                  <input
                    type="range"
                    id="brightness"
                    min="0"
                    max="200"
                    value={brightness}
                    onChange={(e) => setBrightness(e.target.value)}
                    className="w-full mt-2"
                  />
                </div>
                <div>
                  <label
                    htmlFor="contrast"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Contrast: {contrast}%
                  </label>
                  <input
                    type="range"
                    id="contrast"
                    min="0"
                    max="200"
                    value={contrast}
                    onChange={(e) => setContrast(e.target.value)}
                    className="w-full mt-2"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Action Buttons */}
          {image && (
            <div className="flex space-x-4 mb-6">
              <button
                onClick={handleEnhanceImage}
                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition-transform transform hover:scale-105"
              >
                Enhance Image
              </button>
              <button
                onClick={handleReset}
                className="bg-gray-300 text-gray-700 py-2 px-6 rounded-lg shadow-lg hover:bg-gray-400 transition-transform transform hover:scale-105"
              >
                Reset
              </button>
            </div>
          )}

          {/* Preview Section */}
          {enhancedImage && (
            <section className="bg-gray-100 shadow-lg rounded-lg p-8 mt-6">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Enhanced Image Preview
              </h3>
              <img
                src={enhancedImage}
                alt="Enhanced"
                className="max-w-full rounded-lg shadow-md"
              />
              <button className="mt-4 bg-green-500 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-green-600 transition-transform transform hover:scale-105">
                Download Image
              </button>
            </section>
          )}
        </section>
      </main>
    </div>
  );
};

export default ImageEnhancement;
