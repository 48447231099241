import { useState, useRef, useEffect } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa6';

const Accordion = ({ title, content, className, defaultOpen = false }) => {
  const contentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [maxHeight, setMaxHeight] = useState('0px');

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const formatText = (input) => {
    return (
      input
        .toLowerCase()
        .split('_')
        // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    );
  };

  useEffect(() => {
    setMaxHeight(isOpen ? `${contentRef.current?.scrollHeight}px` : '0px');

    const handleResize = () => {
      const newMaxHeight = isOpen
        ? `${contentRef.current?.scrollHeight}px`
        : '0px';
      setMaxHeight(newMaxHeight);
    };

    if (typeof window !== 'undefined') {
      window?.addEventListener('resize', handleResize);
    }

    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, [isOpen, contentRef]);

  return (
    <div
      className={`border border-blue-500 py-3 last:mb-0 mb-4 rounded-md px-4 ${className?.mainWrapperClassName}`}
    >
      <div
        className={`cursor-pointer select-none flex items-center justify-between transition duration-300 ease-in-out ${className?.titleClassName}`}
        onClick={handleClick}
      >
        <div className="text-blue-500 font-bold capitalize text-base font-poppins">
          {formatText(title)}
        </div>
        {isOpen ? (
          <FaMinus className="w-3.5 text-blue-500" />
        ) : (
          <FaPlus className="w-3.5 text-blue-500" />
        )}
      </div>

      <div
        ref={contentRef}
        style={{
          maxHeight,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease-in-out',
        }}
        className={`transition-all ${className?.contentClassName}`}
      >
        {content}
      </div>
    </div>
  );
};

export default Accordion;
