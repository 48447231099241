import { create } from "zustand";
import axios from "axios";

const initialMessages = [
  {
    id: 1,
    text: "Welcome to AI Assistant. How can I assist you with your public sector or government-related inquiries today?",
    isBot: true,
  },
];

const predeterminedHistories = [
  {
    id: 1,
    messages: [
      {
        id: 1,
        text: "Welcome to GovAssist. How can I help you today?",
        isBot: true,
      },
      { id: 2, text: "How can I apply for a government grant?", isBot: false },
      {
        id: 3,
        text: "To apply for a government grant, you need to identify the right grant for your needs by searching on the official government grant website or portal. Once you find a suitable grant, carefully review the eligibility criteria and application requirements. Prepare the necessary documents, complete the application form accurately, and submit it before the deadline. You may also seek assistance from relevant government departments or agencies if you have any questions.",
        isBot: true,
      },
    ],
  },
  {
    id: 2,
    messages: [
      {
        id: 1,
        text: "Welcome to GovAssist. How can I help you today?",
        isBot: true,
      },
      {
        id: 2,
        text: "What are the steps to start a small business?",
        isBot: false,
      },
      {
        id: 3,
        text: "To start a small business, follow these steps: 1. Conduct market research to understand the industry and target audience. 2. Write a business plan outlining your goals, strategies, and financial projections. 3. Choose a business structure (e.g., sole proprietorship, partnership, corporation) and register your business with the appropriate government authorities. 4. Obtain necessary licenses and permits. 5. Set up your business location and establish an online presence. 6. Arrange financing, if needed, through loans, grants, or investors. 7. Launch your business and begin marketing your products or services. Seek guidance from local government resources and support programs for small businesses.",
        isBot: true,
      },
    ],
  },
  {
    id: 3,
    messages: [
      {
        id: 1,
        text: "Welcome to GovAssist. How can I help you today?",
        isBot: true,
      },
      {
        id: 2,
        text: "How can I get information on public transportation in my city?",
        isBot: false,
      },
      {
        id: 3,
        text: "You can get information on public transportation in your city by visiting the official website of your city's public transportation authority. Most cities provide details on routes, schedules, fares, and service alerts. You can also download transportation apps or use online maps that offer real-time updates on transit options. Additionally, you can contact the customer service of your local public transportation authority for assistance.",
        isBot: true,
      },
    ],
  },
];

const useChatStore = create((set, get) => ({
  messages: initialMessages,
  histories: predeterminedHistories,

  isLoading: false,
  error: null,
  userId: "12345", // This should be dynamically set when a user logs in
  sessionId: "abcde", // This should be dynamically generated for each session
  selectedHistoryId: null,

  sendMessage: async (question) => {
    set({ isLoading: true, error: null });

    try {
      // Add user message
      const userMessage = { id: Date.now(), text: question, isBot: false };
      set((state) => ({
        messages: [...state.messages, userMessage],
      }));

      // Make API call
      const response = await axios.post(
        `${process.env.REACT_APP_CHAT_API_PATH}/conversation`,
        {
          question,
          user_id: get().userId,
          session_id: get().sessionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Add bot response
      const botMessage = {
        id: Date.now() + 1,
        text: response.data.response,
        isBot: true,
      };
      set((state) => ({
        messages: [...state.messages, botMessage],
        isLoading: false,
      }));

      // Update the selected history with the new messages
      const selectedHistoryId = get().selectedHistoryId;
      if (selectedHistoryId !== null) {
        set((state) => {
          const updatedHistories = state.histories.map((history) =>
            history.id === selectedHistoryId
              ? {
                  ...history,
                  messages: [...history.messages, userMessage, botMessage],
                }
              : history
          );
          return { histories: updatedHistories };
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      set({
        error: "Failed to send message. Please try again.",
        isLoading: false,
      });
    }
  },

  selectHistory: (index) => {
    const selectedHistory = get().histories[index];
    if (selectedHistory) {
      set({
        messages: [...selectedHistory.messages],
        selectedHistoryId: selectedHistory.id,
        isLoading: false,
        error: null,
      });
    }
  },

  addNewHistory: () => {
    const newHistory = {
      id: get().histories.length + 1,
      messages: [...initialMessages],
    };
    set((state) => ({
      histories: [...state.histories, newHistory],
      messages: newHistory.messages,
      selectedHistoryId: newHistory.id,
      isLoading: false,
      error: null,
    }));
  },

  clearMessages: () =>
    set({
      messages: initialMessages,
      selectedHistoryId: null,
      isLoading: false,
      error: null,
    }),
}));

export default useChatStore;
