import React from 'react';
import AvahiColorIcon from '../../assets/icons/AvahiColorIcon';
import { StarIcon } from '../../assets/icons/StarIcon';

const FileUpload = ({ title, setIsGetStarted, data }) => {
  return (
    <div className="max-h-full my-auto h-full flex-col overflow-y-auto flex items-center  ">
      <div className="max-w-[350px] px-4 flex flex-col gap-12 w-full m-auto">
        <div className="flex items-center gap-4 flex-col">
          <AvahiColorIcon />
          <h2 className="font-poppins text-2xl text-center dark:text-white text-blackDark-10 font-semibold">
            {title}
          </h2>
        </div>

        <div className="flex flex-col gap-6">
          {data.map(({ id, img, label }) => (
            <div key={id} className="flex items-start gap-4">
              <img src={img} alt="images" className="w-4 h-4" />
              <h3 className="font-poppins text-base text-blackDark-10 dark:text-white font-normal">
                {label}
              </h3>
            </div>
          ))}
        </div>

        <button
          onClick={() => setIsGetStarted(true)}
          className="py-2 mx-auto rounded-lg px-3 font-poppins flex items-center gap-2 hover:bg-blue-45 duration-300 text-white text-base font-medium bg-blue-20"
        >
          <StarIcon />
          Get started
        </button>
      </div>
    </div>
  );
};

export default FileUpload;
