import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IoMdMenu } from 'react-icons/io';
import { CgTranscript } from 'react-icons/cg';
import {
  Search,
  MessageCircle,
  LogOut,
  Sun,
  Database,
  FileText,
  Filter,
  GitMerge,
  Image,
  Shield,
  Clipboard,
  HelpCircle,
  RefreshCw,
  File,
  Activity,
  DollarSign,
  ThumbsUp,
  Edit,
  Briefcase,
  Grid,
  Mail,
  UserX,
  Paperclip,
  LifeBuoy,
  Film,
  Box,
  Codesandbox,
  Dribbble,
  Tv,
  Trello,
  StopCircle,
  Thermometer,
  Sliders,
  Map,
  BarChart,
  Tool,
  Folder,
  Heart,
  Users,
} from 'react-feather';
import useAuthStore from '../stores/authStore';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import MobileLeftPanel from './MobileLeftPanel';
import { MdIntegrationInstructions } from 'react-icons/md';
import LeftPanelHeader from './LeftPanelFiles/LeftPanelHeader';
import CategoryDropdown from './LeftPanelFiles/CategoryDropdown';
import MenuItem from './LeftPanelFiles/MenuItem';
import LeftPanelExpanded from './LeftPanelFiles/LeftPanelExpanded';
import AvahiIcon from '../assets/icons/AvahiIcon';
import LeftPanelNotebook from './LeftPanelFiles/LeftPanelNotebook';
import SingleFileUploadModel from './SingleFileUploadModel';

const LeftPanel = ({ isOpen, setIsLeftPanelOpen }) => {
  const location = useLocation();

  const { isLoggedIn, toggleLogoutPopup, toggleDeleteAccountPopup } =
    useAuthStore();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Enterprise');
  const [expandedSections, setExpandedSections] = useState({
    Tools: false,
    Objects: false,
  });

  const [selectedLanguage, setSelectedLanguage] = useState({
    code: 'en',
    name: 'English',
    country_code: 'gb',
    icon: '/images/english.png',
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotebookPanel, setIsNotebookPanel] = useState(false);
  const [expandedManuOpen, setExpandedManuOpen] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [file, setFile] = useState(null);
  const [fileModelOpen, setFileModelOpen] = useState(false);

  const { t } = useTranslation('menu');

  const categories = {
    Enterprise: [
      { icon: Grid, text: t('Enterprice.Dashboard'), path: '/dashboard' },
      {
        icon: BarChart,
        text: t('Enterprice.AllReports'),
        path: '/all-reports',
      },
      // { icon: Mail, text: t("Enterprice.Inbox"), path: "/inbox" },
      // {
      //   icon: MessageCircle,
      //   text: t("Enterprice.Assistant"),
      //   path: "/chat",
      // },
      // {
      //   icon: MessageCircle,
      //   text: t('Enterprice.AssistantV2'),
      //   path: '/assistant',
      // },
      {
        icon: MessageCircle,
        text: t('Enterprice.AssistantNew'),
        path: '/smartassistant',
      },
      // {
      //   icon: LifeBuoy,
      //   text: t("Enterprice.ZenDesk"),
      //   path: "/zendesk",
      // },
      // {
      //   icon: LifeBuoy,
      //   text: t("Enterprice.ZenDeskV1"),
      //   path: "/helpdesk",
      // },

      // {
      //   icon: Database,
      //   text: `${t("Enterprice.NL2SQL")} V1`,
      //   path: "/nl2sql-v1",
      // },

      // {
      //   icon: Clipboard,
      //   text: t('Enterprice.MedicalScribingNew'),
      //   path: '/medical-scribing-new',
      // },
      // {
      //   icon: Clipboard,
      //   text: 'Medical Scribing V1',
      //   path: '/medical-scribing-v1',
      // },

      {
        section: t('Enterprice.Tools'),
        icon: Tool,
        objects: {
          text: 'Coming soon',
          items: [
            {
              icon: Mail,
              text: t('Enterprice.Inbox'),
              //path: "/inbox"
              path: '#',
            },
            {
              icon: LifeBuoy,
              text: t('Enterprice.ZenDeskV1'),
              // path: "/helpdesk",
              path: '#',
            },
            {
              icon: Database,
              text: t('Enterprice.NL2SQL'),
              //  path: "/nl2sql",
            },

            {
              icon: Paperclip,
              text: t('Enterprice.ProductRecommendation'),
              // path: '/product-recommendation',
              path: '#',
            },
            {
              icon: Database,
              text: t('Enterprice.GenerateContent'),
              // path: '/generate-content',
              path: '#',
            },
            {
              icon: Image,
              text: t('Enterprice.ImageEnhancement'),
              // path: '/image-enhancement',
              path: '#',
            },
            {
              icon: FileText,
              text: t('Enterprice.ReviewContracts'),
              // path: '/review-contracts',
              path: '#',
            },
            {
              icon: Search,
              text: t('Enterprice.EnterpriseSearch'),
              // path: '/enterprise-search',
              path: '#',
            },
            {
              icon: Filter,
              text: t('Enterprice.DataExtractor'),
              // path: '/data-extractor',
              path: '#',
            },
            {
              icon: GitMerge,
              text: t('Enterprice.EntityResolution'),
              // path: '/entity-resolution',
              path: '#',
            },

            {
              icon: RefreshCw,
              text: t('Enterprice.OpenAIToBedrock'),
              // path: '/openai-to-bedrock',
              path: '#',
            },
          ],
        },
        notebook: {
          text: t('Enterprice.MedicalScribingv1'),
          items: [
            {
              icon: Folder,
              text: t('Enterprice.AllNotes'),
              path: '/all-notes',
            },
            {
              icon: Heart,
              text: t('Enterprice.FavoriteNotes'),
              path: '/favorite-notes',
            },
            {
              icon: Users,
              text: t('Enterprice.Patients'),
              path: '/patients',
            },
          ],
        },
        items: [
          {
            icon: Activity,
            text: t('Enterprice.Summarizer'),
            path: '/summarizer',
          },
          {
            icon: Briefcase,
            text: t('Enterprice.StructuredExtraction'),
            path: '/structured-extraction',
          },
          {
            icon: Image,
            text: t('Enterprice.ImageGeneration'),
            path: '/image-generation',
          },
          // {
          //   icon: Database,
          //   text: t("Enterprice.NL2SQL"),
          //   path: "/nl2sql",
          // },

          {
            icon: File,
            text: t('Enterprice.PDFViewer'),
            path: '/pdf-viewer',
          },

          {
            icon: Shield,
            text: t('Enterprice.DataMasking'),
            path: '/data-masking',
          },
          {
            icon: Clipboard,
            text: t('Enterprice.MedicalScribing'),
            path: '/medical-scribing',
          },
          // {
          //   icon: Clipboard,
          //   text: t('Enterprice.MedicalScribingv1'),
          //   path: '/medical-scribing-v1',
          // },
          {
            icon: FileText,
            text: t('Enterprice.CSVQuerying'),
            path: '/csv-querying',
          },
          {
            icon: CgTranscript,
            text: t('Enterprice.LiveTranscription'),
            path: '/all-notes-livetranscription',
          },
          // {
          //   icon: CgTranscript,
          //   text: t('Enterprice.LiveTranscript'),
          //   path: '/live-transcript',
          // },
        ],
      },
      // {
      //   section: t('Enterprice.Objects'),
      //   icon: Users,
      //   items: [
      //     { icon: File, text: t('Enterprice.Files'), path: '/files' },
      //     {
      //       icon: File,
      //       text: t('Enterprice.Documents'),
      //       path: '/documents',
      //     },
      //     { icon: Image, text: t('Enterprice.Images'), path: '/images' },
      //   ],
      // },
    ],
    'Public Sector': [
      { icon: Grid, text: t('PublicSector.Dashboard'), path: '/dashboard' },
      {
        icon: BarChart,
        text: t('Enterprice.AllReports'),
        path: '/all-reports',
      },
      // { icon: Mail, text: t("PublicSector.Inbox"), path: "/inbox" },
      // { icon: MessageCircle, text: t("PublicSector.Assistant"), path: "/chat" },
      // {
      //   icon: MessageCircle,
      //   text: t('PublicSector.Assistant'),
      //   path: '/chat',
      // },
      {
        icon: MessageCircle,
        text: t('Enterprice.AssistantNew'),
        path: '/smartassistant',
      },
      // {
      //   icon: LifeBuoy,
      //   text: t("Enterprice.ZenDeskV1"),
      //   path: "/helpdesk",
      // },
      // {
      //   section: t("PublicSector.Tools"),
      //   icon: Tool,
      //   items: [

      //   ],
      // },

      {
        section: t('Enterprice.Tools'),
        icon: Tool,
        objects: {
          text: 'Coming soon',
          items: [
            {
              icon: Mail,
              text: t('PublicSector.Inbox'),
              //path: "/inbox"
              path: '#',
            },
            {
              icon: LifeBuoy,
              text: t('Enterprice.ZenDeskV1'),
              // path: "/helpdesk",
              path: '#',
            },

            {
              icon: Image,
              text: t('PublicSector.ImageGeneration'),
              // path: '/image-generation',
              path: '#',
            },
            {
              icon: FileText,
              text: t('PublicSector.MeetingSummarizer'),
              // path: '/meeting-summarizer',
              path: '#',
            },
            {
              icon: DollarSign,
              text: t('PublicSector.TaxProcessing'),
              // path: '/tax-processing',
              path: '#',
            },
            {
              icon: FileText,
              text: t('PublicSector.ContractManagement'),
              // path: '/contract-management',
              path: '#',
            },
            {
              icon: Search,
              text: t('PublicSector.SmartSearch'),
              // path: '/smart-search',
              path: '#',
            },
          ],
        },
        items: [
          {
            icon: File,
            text: t('PublicSector.PDFViewer'),
            path: '/pdf-viewer',
          },
        ],
      },

      // {
      //   section: t('PublicSector.Objects'),
      //   icon: Users,
      //   items: [
      //     { icon: File, text: t('PublicSector.Files'), path: '/files' },
      //     { icon: File, text: t('PublicSector.Documents'), path: '/documents' },
      //     { icon: Image, text: t('PublicSector.Images'), path: '/images' },
      //   ],
      // },
    ],
    Healthcare: [
      { icon: Grid, text: t('Healthcare.Dashboard'), path: '/dashboard' },
      {
        icon: BarChart,
        text: t('Enterprice.AllReports'),
        path: '/all-reports',
      },
      // { icon: Mail, text: t("Healthcare.Inbox"), path: "/inbox" },
      // { icon: MessageCircle, text: t('Healthcare.Assistant'), path: '/chat' },
      // {
      //   icon: MessageCircle,
      //   text: t('Healthcare.Assistant'),
      //   path: '/chat',
      // },
      {
        icon: MessageCircle,
        text: t('Enterprice.AssistantNew'),
        path: '/smartassistant',
      },
      // {
      //   icon: LifeBuoy,
      //   text: t("Enterprice.ZenDeskV1"),
      //   path: "/helpdesk",
      // },

      {
        section: t('Healthcare.Tools'),
        icon: Tool,
        objects: {
          text: 'Coming soon',
          items: [
            {
              icon: Mail,
              text: t('Healthcare.Inbox'),
              //  path: "/inbox"
              path: '#',
            },
            {
              icon: LifeBuoy,
              text: t('Enterprice.ZenDeskV1'),
              // path: "/helpdesk",
              path: '#',
            },
            {
              icon: RefreshCw,
              text: t('Healthcare.OpenAIToBedrock'),
              // path: '/openai-to-bedrock',
              path: '#',
            },
            {
              icon: Activity,
              text: t('Healthcare.ClaimsAI'),
              // path: '/claims-ai',
              path: '#',
            },
            {
              icon: ThumbsUp,
              text: t('Healthcare.PriorAuthorization'),
              // path: '/prior-authorization',
              path: '#',
            },
            {
              icon: DollarSign,
              text: t('Healthcare.BillingAI'),
              // path: '/billing-ai',
              path: '#',
            },
            {
              icon: Edit,
              text: t('Healthcare.CodingAI'),
              // path: '/coding-ai',
              path: '#',
            },
            {
              icon: Search,
              text: t('Healthcare.VisualSearch'),
              // path: '/visualsearch',
              path: '#',
            },
            {
              icon: Image,
              text: t('Healthcare.MedicalImageSegmentation'),
              // path: '/segmentation',
              path: '#',
            },
            {
              icon: Film,
              text: t('Healthcare.ImageClassification'),
              // path: '/classifcation',
              path: '#',
            },
            {
              icon: Box,
              text: t('Healthcare.EhrSumarizer'),
              // path: '/ehr-summarizer',
              path: '#',
            },
            {
              icon: Codesandbox,
              text: t('Healthcare.MedicalImagingAnalysis'),
              // path: '/medical-imaging-analysis',
              path: '#',
            },

            {
              icon: Dribbble,
              text: t('Healthcare.DiffernetialDiagnosis'),
              // path: '/differential-diagnosis',
              path: '#',
            },

            {
              icon: Tv,
              text: t('Healthcare.AutomaticInsightGeneration'),
              // path: '/automatic-insight-generation',
              path: '#',
            },

            {
              icon: Trello,
              text: t('Healthcare.AiPowerTreatment'),
              // path: '/ai-power-treatment',
              path: '#',
            },
            {
              icon: StopCircle,
              text: t('Healthcare.HippaCompliantData'),
              // path: '/hippa-compliant-data',
              path: '#',
            },
            {
              icon: Sun,
              text: t('Healthcare.AutomationDdiForm'),
              // path: '/automatic-edi-form-filling',
              path: '#',
            },
            {
              icon: Sliders,
              text: t('Healthcare.AutomationPayerBehaviour'),
              // path: '/automatic-payer-behaviour',
              path: '#',
            },
            {
              icon: Thermometer,
              text: t('Healthcare.ClaimDenialAssistant'),
              // path: '/claim-denial-assistant',
              path: '#',
            },
            {
              icon: Map,
              text: t('Healthcare.VirtualDrugTrial'),
              // path: '/virtual-drug-trials',
              path: '#',
            },
          ],
        },
        items: [
          {
            icon: Shield,
            text: t('Healthcare.DataMasking'),
            path: '/data-masking',
          },
          { icon: File, text: t('Healthcare.PDFViewer'), path: '/pdf-viewer' },

          {
            icon: Clipboard,
            text: t('Healthcare.MedicalScribing'),
            path: '/healthcare-medical-scribing',
          },
        ],
      },

      // {
      //   section: t('Healthcare.Objects'),
      //   icon: Users,
      //   items: [
      //     { icon: File, text: t('Healthcare.Files'), path: '/files' },
      //     { icon: File, text: t('Healthcare.Documents'), path: '/documents' },
      //     { icon: Image, text: t('Healthcare.Images'), path: '/images' },
      //   ],
      // },
    ],
    Finance: [
      { icon: Grid, text: t('Finance.Dashboard'), path: '/dashboard' },
      {
        icon: BarChart,
        text: t('Enterprice.AllReports'),
        path: '/all-reports',
      },
      // { icon: Mail, text: t("Finance.Inbox"), path: "/inbox" },

      // {
      //   icon: MessageCircle,
      //   text: t('Healthcare.Assistant'),
      //   path: '/chat',
      // },
      {
        icon: MessageCircle,
        text: t('Enterprice.AssistantNew'),
        path: '/smartassistant',
      },

      // {
      //   icon: LifeBuoy,
      //   text: t("Enterprice.ZenDeskV1"),
      //   path: "/helpdesk",
      // },

      {
        section: t('Finance.Tools'),
        icon: Tool,
        objects: {
          text: 'Coming soon',
          items: [
            {
              icon: Mail,
              text: t('Finance.Inbox'),
              //  path: "/inbox"
              path: '#',
            },
            {
              icon: LifeBuoy,
              text: t('Enterprice.ZenDeskV1'),
              // path: "/helpdesk",
              path: '#',
            },
            {
              icon: RefreshCw,
              text: t('Finance.OpenAIToBedrock'),
              // path: '/openai-to-bedrock',
              path: '#',
            },
            {
              icon: Briefcase,
              text: t('Finance.SettlementProcessing'),
              // path: '/settlement-processing',
              path: '#',
            },
            {
              icon: MessageCircle,
              text: t('Finance.LendingCopilot'),
              // path: '/lending-copilot',
              path: '#',
            },
          ],
        },
        items: [
          {
            icon: Shield,
            text: t('Finance.DataMasking'),
            path: '/data-masking',
          },

          { icon: File, text: t('Finance.PDFViewer'), path: '/pdf-viewer' },
        ],
      },

      // {
      //   section: t('Finance.Objects'),
      //   icon: Users,
      //   items: [
      //     { icon: File, text: t('Finance.Files'), path: '/files' },
      //     { icon: File, text: t('Finance.Documents'), path: '/documents' },
      //     { icon: Image, text: t('Finance.Images'), path: '/images' },
      //   ],
      // },
    ],
  };

  const toggleSection = (section) => {
    if (!isSmallScreen && isOpen) {
      setExpandedSections(() => ({
        [`Tools`]: true,
        [`Objects`]: true,
      }));
      setIsNotebookPanel(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const swiperSection = document?.querySelector('#all-notes-swiper');
    const swiperSectionpatients = document?.querySelector(
      '#patients-all-notes-swiper'
    );

    if (expandedSections?.Tools) {
      swiperSection?.classList?.add('open-sub-sidebar');
      swiperSectionpatients?.classList?.add('open-sub-sidebar');
    } else {
      swiperSection?.classList?.remove('open-sub-sidebar');
      swiperSectionpatients?.classList?.remove('open-sub-sidebar');
    }
  }, [expandedSections, location]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isMenuOpen]);

  const renderMenuItem = (item) => (
    <MenuItem
      key={item.text}
      icon={item.icon}
      text={item.text}
      path={item.path}
      isOpen={isOpen}
      isSmallScreen={isSmallScreen}
      expandedSections={expandedSections}
      setExpandedSections={setExpandedSections}
    />
  );

  const renderSection = (section) => (
    <div key={section.section} className="mx-3">
      <button
        onClick={() => toggleSection(section.section)}
        className={`${
          expandedSections?.Objects
            ? 'bg-blue-10 text-blue-20 font-medium border-blue-20 dark:text-white dark:!border-gray-500 dark:bg-gray-700'
            : 'text-gray-600 border-transparent  hover:font-medium hover:border-transparent hover:bg-blue-10 dark:hover:!border-transparent dark:text-gray-300  dark:hover:bg-gray-700'
        } flex items-center border rounded-lg w-full p-2  ${
          isOpen && !isSmallScreen ? 'justify-start' : 'justify-center'
        } mt-2`}
      >
        <section.icon size={20} />
        {isOpen && !isSmallScreen && (
          <span className="ml-3">{section.section}</span>
        )}
      </button>
    </div>
  );

  const bottomMenuItemClass = `flex items-center border-transparent dark:hover:border-gray-500 border w-full px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-blue-10 hover:border-blue-20 hover:text-blue-20 dark:hover:bg-gray-700 transition-all duration-200 rounded-md ${
    isOpen && !isSmallScreen ? 'justify-start' : 'justify-center'
  }`;

  return (
    <>
      <motion.aside
        initial={false}
        animate={{
          width: isSmallScreen || !isOpen ? '70px' : '200px',
        }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className="bg-[#FCFCFC] border-r border-gray-10 dark:border-gray-600  dark:bg-gray-900 font-poppins flex-shrink-0 h-full shadow-lg lg:flex flex-col hidden"
        onMouseEnter={() => {
          setIsLeftPanelOpen(true);

          const swiperSection = document?.querySelector('#all-notes-swiper');
          const swiperSectionpatients = document?.querySelector(
            '#patients-all-notes-swiper'
          );

          swiperSection?.classList?.add('open-main-sidebar');
          swiperSectionpatients?.classList?.add('open-main-sidebar');
        }}
        onMouseLeave={() => {
          setIsLeftPanelOpen(false);
          setTimeout(() => {
            document
              ?.querySelector('#all-notes-swiper')
              ?.classList?.remove('open-main-sidebar');
            document
              ?.querySelector('#patients-all-notes-swiper')
              ?.classList?.remove('open-main-sidebar');
          }, 300);
        }}
      >
        <LeftPanelHeader
          isOpen={isOpen}
          isSmallScreen={isSmallScreen}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />

        {!isSmallScreen && (
          <>
            <CategoryDropdown
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              categories={categories}
              openPanel={isOpen}
              setExpandedManuOpen={setExpandedManuOpen}
            />
          </>
        )}

        <nav
          className={`${
            isOpen ? 'justify-start' : 'justify-center'
          } flex-1 overflow-y-auto mt-2 helpdesk-custom-scrollbar scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent`}
        >
          {categories[selectedCategory].map((item) =>
            item.section ? renderSection(item) : renderMenuItem(item)
          )}
        </nav>

        <div className="p-4 border-t border-gray-200 dark:border-gray-700 space-y-2">
          <NavLink
            to="/help"
            className={({ isActive }) =>
              `${bottomMenuItemClass}  ${
                isActive
                  ? 'bg-blue-10 border-blue-20 hover:!bg-blue-10 dark:hover:!bg-gray-700 dark:bg-gray-700 dark:!border-gray-500 dark:!text-white  !text-[#377FFF] font-semibold'
                  : 'border-transparent'
              } `
            }
          >
            <HelpCircle size={20} className="flex-shrink-0" />
            {isOpen && !isSmallScreen && (
              <span className="ml-3 truncate text-sm">Help</span>
            )}
          </NavLink>

          <NavLink
            to="/integration"
            className={({ isActive }) =>
              `${bottomMenuItemClass}  ${
                isActive
                  ? 'bg-blue-10 border-blue-20 hover:!bg-blue-10 dark:hover:!bg-gray-700 dark:bg-gray-700 dark:!border-gray-500 dark:!text-white  !text-[#377FFF] font-semibold'
                  : 'border-transparent'
              } `
            }
          >
            <MdIntegrationInstructions size={20} className="flex-shrink-0" />
            {isOpen && !isSmallScreen && (
              <span className="ml-3 truncate text-sm">Integration</span>
            )}
          </NavLink>
          <button
            onClick={toggleDeleteAccountPopup}
            className={bottomMenuItemClass}
          >
            <UserX size={20} className="flex-shrink-0" />
            {isOpen && !isSmallScreen && (
              <span className="ml-3 truncate text-sm">Delete Account</span>
            )}
          </button>
          {isLoggedIn && (
            <button
              onClick={toggleLogoutPopup}
              className={`${bottomMenuItemClass} ${
                isOpen && !isSmallScreen
                  ? 'bg-transparent dark:bg-transparent'
                  : ''
              }`}
            >
              <LogOut size={20} className="flex-shrink-0" />
              {isOpen && !isSmallScreen && (
                <span className="ml-3 truncate text-sm">Logout</span>
              )}
            </button>
          )}
        </div>
      </motion.aside>

      {expandedSections?.Objects && !isNotebookPanel && (
        <LeftPanelExpanded
          categories={categories}
          selectedCategory={selectedCategory}
          expandedSections={expandedSections}
          setExpandedSections={setExpandedSections}
          setOpenMenu={setExpandedManuOpen}
          manuOpen={expandedManuOpen}
          setOpenNote={setOpenNote}
          openNote={openNote}
          setIsNotebookPanel={setIsNotebookPanel}
        />
      )}

      {expandedSections?.Objects && isNotebookPanel && (
        <LeftPanelNotebook
          categories={categories}
          selectedCategory={selectedCategory}
          expandedSections={expandedSections}
          setExpandedSections={setExpandedSections}
          setOpenMenu={setExpandedManuOpen}
          setOpenNote={setOpenNote}
          setFileModelOpen={setFileModelOpen}
        />
      )}

      <SingleFileUploadModel
        handleUploadFile={() => {}}
        isOpen={fileModelOpen}
        file={file}
        setFile={setFile}
        setIsOpen={setFileModelOpen}
      />

      <motion.aside
        initial={false}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className="!bg-white dark:!bg-gray-900 sticky top-0 left-0 py-2 px-5 items-center justify-between   font-poppins flex-shrink-0 w-full shadow-md flex lg:hidden z-[9999]"
      >
        <div className="flex items-center ">
          <div className={` w-fit `}>
            <AvahiIcon />
          </div>
          <h1 className="text-base text-start font-semibold">
            Avahi AI Platform
          </h1>
        </div>

        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <IoMdMenu size={25} className="dark:text-white text-gray-900" />
        </button>
      </motion.aside>

      {isMenuOpen && (
        <MobileLeftPanel
          setIsMenuOpen={setIsMenuOpen}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={categories}
          isLoggedIn={isLoggedIn}
          isOpenMenu={isMenuOpen}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          setExpandedManuOpen={setExpandedManuOpen}
          setOpenMenu={setExpandedManuOpen}
          manuOpen={expandedManuOpen}
        />
      )}
    </>
  );
};

export default LeftPanel;
