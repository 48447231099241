import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
// import HeaderSettingIcon from '../../../assets/icons/HeaderSettingIcon';
import { toast } from 'react-toastify';
import axios from 'axios';
import TranslateTextHeader from '../../TranslateTextHeader';
import FileUpload from '../../SummarizerNew/FileUpload';
import SingleFileUploadModel from '../../SingleFileUploadModel';
import DataExtractionText from './DataExtraction';
import StructureExtractionSettingsModel from './StructureExtractionSettingsModel';

const SETTINGS_DATA = [
  {
    id: 1,
    label: 'Names',
    isSelect: true,
  },
  {
    id: 2,
    label: 'Places',
    isSelect: true,
  },
  {
    id: 3,
    label: 'Emails',
    isSelect: true,
  },
  {
    id: 4,
    label: 'Phone Numbers',
    isSelect: true,
  },
  {
    id: 5,
    label: 'Addresses',
    isSelect: true,
  },
  {
    id: 6,
    label: 'Credit Cards',

    isSelect: true,
  },
  {
    id: 7,
    label: 'SSNs',
    isSelect: true,
  },
];

const REQUIREMENTS = [
  {
    id: 1,
    label: 'Enhances and expedites text conversion in an intuitive layout.',
    img: '/images/planIcon.png',
  },
  {
    id: 2,
    label: 'Upload .txt, .dox, and .pdf files.',
    img: '/images/BulbIcon.png',
  },
  {
    id: 3,
    label: 'Generate summaries and view the original text side-by-side.',
    img: '/images/InfoIcon.png',
  },
];

const StructureExtractionNew = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [extractionData, setExtractionData] = useState(null);
  const [uploadFileText, setUploadFileText] = useState(null);
  const [isAline, setIsAline] = useState('horizontal');
  const [isActiveAline, setIsActiveAline] = useState(true);
  const [isShowInput, setIsShowInput] = useState(false);
  const [fileModelOpen, setFileModelOpen] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isExtractionDisable, setIsExtractionDisable] = useState(false);
  const [file, setFile] = useState(null);
  const [isGetStarted, setIsGetStarted] = useState(false);
  const [isExtractionSettings, setIsExtractionSettings] = useState(false);
  const [settings, setSettings] = useState(SETTINGS_DATA);

  const handleStructureExtraction = async () => {
    if (uploadFileText) {
      setIsLoading(true);
      try {
        const extract_entities = settings
          .filter((item) => item.isSelect)
          .map((item) => item.label);

        const response = await axios.post(
          // `${process.env.REACT_APP_API_GENERATION}/api/structure_extraction`,
          `${process.env.REACT_APP_API_PATH}/api/structure_extraction`,
          {
            input_text: uploadFileText,
            extract_entities: extract_entities,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.statusCode === 200) {
          setIsLoading(false);
          setIsActiveAline(false);
          setIsExtractionDisable(true);
          const parsedBody = JSON.parse(response.data.body);
          const parsedData = JSON.parse(parsedBody?.extraction);
          setExtractionData(parsedData);
        } else {
          toast.error('Summarize failed');
          throw new Error('Summarize failed');
        }
      } catch (error) {
        console.error('Error', error);
      }
    }
  };

  const handleUploadFile = async () => {
    setIsFileLoading(true);
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/api/extract/upload_doc_and_extract_text`,
          formData,
          {
            maxBodyLength: Infinity,
          }
        );

        if (response.data.statusCode === 200) {
          setFile(null);
          setIsFileLoading(false);
          setIsExtractionDisable(false);
          setFileModelOpen(false);
          const parsedText = JSON.parse(response.data.body);
          setUploadFileText(parsedText?.extracted_text);
          setIsShowInput(true);
          toast.success('File uploaded successfully!');
        } else {
          toast.error('File upload failed.');
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('Error', error);
        toast.error('Error uploading file.');
      } finally {
        setIsFileLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window !== undefined) {
        const windowWidth = window.innerWidth;

        if (windowWidth <= 1024) {
          setIsAline('vertical');
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] lg:h-screen bg-[#FCFCFC] dark:bg-gray-900 overflow-auto">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="h-full"
        >
          <TranslateTextHeader
            setIsAline={setIsAline}
            isActiveAline={isActiveAline}
            isAline={isAline}
            title={'Structured Extraction'}
            isExtractionSettings={true}
            setIsExtractionSettings={setIsExtractionSettings}
          />

          {!isGetStarted && (
            <div className="h-[calc(100vh-60px)] py-3">
              <FileUpload
                title={'Structured Extraction'}
                setIsGetStarted={setIsGetStarted}
                data={REQUIREMENTS}
              />
            </div>
          )}

          {(isShowInput || isGetStarted) && (
            <DataExtractionText
              isAline={isAline}
              uploadFileText={uploadFileText}
              setUploadFileText={setUploadFileText}
              setFileModelOpen={setFileModelOpen}
              setIsAline={setIsAline}
              handleStructureExtraction={handleStructureExtraction}
              isLoading={isLoading}
              extractionData={extractionData}
              setIsExtractionDisable={setIsExtractionDisable}
              isExtractionDisable={isExtractionDisable}
            />
          )}
        </motion.div>
      </AnimatePresence>

      {isLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center backdrop-blur-sm">
          <span className="loader" />
        </div>
      )}

      <SingleFileUploadModel
        handleUploadFile={handleUploadFile}
        isOpen={fileModelOpen}
        file={file}
        setFile={setFile}
        setIsOpen={setFileModelOpen}
        isLoading={isFileLoading}
      />

      {isExtractionSettings && (
        <div className="h-screen w-screen fixed flex overflow-y-auto justify-center backdrop-blur-[2px] left-0 top-0">
          <div className="max-w-xl  my-auto w-full shadow-shadow-button rounded-xl">
            <StructureExtractionSettingsModel
              isOpen={isExtractionSettings}
              setIsOpen={setIsExtractionSettings}
              settings={settings}
              setSettings={setSettings}
              defaultSettings={SETTINGS_DATA}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StructureExtractionNew;
